import { Button } from "@shopify/polaris";
import { ReactNode, useState } from "react";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import { useQueryComments, useUpdateComment } from "../hooks/commentHooks";
import { QueryHandler } from "../QueryHandler";
import {
  Comment,
  commentFilters,
  commentLabels,
  commentSorts,
} from "../schemas/comment";
import { DatasetNavMap } from "../schemas/core";
import { DefaultPageProps } from "../utils/shared";
import { CommentBlockEmailModal } from "./CommentBlockedEmails";
import CommentDetail from "./CommentDetail";
import { CommentFormModal } from "./CommentForm";

export function CommentFeedPage(props: DefaultPageProps) {
  const [modalActive, setModalActive] = useState(false);
  const [blockPromptActive, setBlockPromptActive] = useState(false);
  const [emailToBlock, setEmailToBlock] = useState<string | undefined>();
  const [selectedCmt, setSelectedCmt] = useState<Comment | undefined>();

  const mutation = useUpdateComment();

  const blockEmailModal = (
    <CommentBlockEmailModal
      currentUserData={props.currentUserData}
      email={emailToBlock}
      setEmail={setEmailToBlock}
      blockPromptActive={blockPromptActive}
      setBlockPromptActive={setBlockPromptActive}
    />
  );

  const createGoToRecordUrl = (cmt: Comment) => {
    return `/rma/${DatasetNavMap[cmt.dataset]}/${cmt.assignedTo}`;
  };

  const renderItem = (item: Comment): ReactNode => (
    <CommentDetail
      comment={item}
      editIsLoading={mutation.isLoading}
      onEditBtnClick={() => {
        setSelectedCmt(item);
        setModalActive(true);
      }}
      onBlockClick={
        hasPermissions(props.currentUserData, "add_cmt_blocked_emails")
          ? () => {
              setEmailToBlock(item.email);
              setBlockPromptActive(true);
            }
          : undefined
      }
      onSubmitEdit={
        hasPermissions(props.currentUserData, "edit_comments")
          ? (input) =>
              mutation.mutate({
                accessToken: props.currentUserData.accessToken,
                inputData: input,
              })
          : undefined
      }
      additionalActions={
        <Button onClick={() => window.open(createGoToRecordUrl(item))}>
          Go To Record
        </Button>
      }
    />
  );

  const editCommentModal = selectedCmt && (
    <CommentFormModal
      promptTitle="Edit Comment"
      currentUserData={props.currentUserData}
      record={selectedCmt}
      modalActive={modalActive}
      setModalActive={setModalActive}
      hideAccessLevel
    />
  );

  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          {blockEmailModal}
          {editCommentModal}
          <QueryHandler
            currentUserData={props.currentUserData}
            queryHook={useQueryComments}
            sortFieldsSpec={commentSorts}
            filterFieldsSpec={commentFilters}
            labels={commentLabels}
            renderResultItem={renderItem}
            renderItemAccessibilityLabel={(item) =>
              `Comment details for comment ${item.id}`
            }
            heading="Comments Activity Feed"
            defaultSorts={{
              commentDt: { field: "commentDt", direction: "desc" },
            }}
          />
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
