import { Frame, Layout, Page, Spinner } from "@shopify/polaris";
import { User as FirebaseUser, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery, useQueryClient } from "react-query";
import { Route, Routes, useNavigate } from "react-router-dom";
import * as env from "../env";
import Stack from "../shared/Stack";
import { Heading } from "../shared/TextComponents";
import { BulkEditDashboard } from "./BulkEditDashboard";
import { EmailAutomationPage } from "./EmailAutomation";
import Home from "./Home";
import ManageAuthorization from "./ManageAuthorization";
import Navigation from "./Navigation";
import SignIn from "./SignIn";
import TopBar from "./TopBar";
import {
  requestEmailVerification,
  signInWithFirebaseIdToken,
} from "./api/endpoints";
import RoleFormPage, { NewRoleFormPage } from "./auth/RoleFormPage";
import RolesPage from "./auth/RolesPage";
import { UserAPICredsPage } from "./auth/UserAPICreds";
import UserDetailPage from "./auth/UserDetailPage";
import { UserAddPage, UserEditPage } from "./auth/UserForm";
import UserProfilePage from "./auth/UserProfilePage";
import VerificationBanner from "./auth/VerificationBanner";
import { hasPermissions } from "./auth/authutils";
import ErrorFallback, { NotAuthorized } from "./errors";
import { JournalActivityPage } from "./journal/Journal";
import {
  BirthRecord,
  birthRecordFreeformDateSpecs,
} from "./schemas/birthRecord";
import {
  CatalogueItem,
  catalogueItemFreeformDateSpecs,
} from "./schemas/catalogueItem";
import { CensusRecord, censusFreeformDateSpecs } from "./schemas/census";
import { CensusPage } from "./schemas/censusPage";
import { PermissionId, SYSADMIN_ROLE_ID } from "./schemas/core";
import {
  DeathRecord,
  deathRecordFreeformDateSpecs,
} from "./schemas/deathRecord";
import { FindingAid } from "./schemas/findAid";
import { GoldStarRecord, goldStarFreeformDateSpecs } from "./schemas/goldStar";
import {
  VetsGraveRecord,
  vetsGraveFreeformDateSpecs,
} from "./schemas/vetsGrave";
import { GenPersistentSearchState } from "./search/SearchState";
import { BirthRecordBulkEditPage } from "./services/BirthRecordBulkEdit";
import { BirthRecordDetailPage } from "./services/BirthRecordDetail";
import {
  BirthRecordAddPage,
  BirthRecordEditPage,
} from "./services/BirthRecordForm";
import BirthRecordSearchPage from "./services/BirthRecordSearch";
import { CensusDetailPage, CensusMediaPage } from "./services/CensusDetail";
import { CensusAddPage, CensusEditPage } from "./services/CensusForm";
import { CensusPageDetailPage } from "./services/CensusPageDetail";
import {
  CensusPageAddPage,
  CensusPageEditPage,
} from "./services/CensusPageForm";
import CensusPageSearchPage from "./services/CensusPageSearch";
import CensusSearchPage from "./services/CensusSearch";
import { DeathRecorDetailPage } from "./services/DeathRecordDetail";
import {
  DeathRecordAddPage,
  DeathRecordEditPage,
} from "./services/DeathRecordForm";
import DeathRecordSearchPage from "./services/DeathRecordSearch";
import { FindaidAddPage, FindaidEditPage } from "./services/FindAidForm";
import { FindAidPreviewPage } from "./services/FindAidPreview";
import FindAidSearchPage from "./services/FindAidSearch";
import { GoldStarDetailPage } from "./services/GoldStarDetail";
import { GoldStarAddPage, GoldStarEditPage } from "./services/GoldStarForm";
import GoldStarSearchPage from "./services/GoldStarSearch";
import { VetsGraveDetailPage } from "./services/VetsGraveDetail";
import { VetsGraveAddPage, VetsGraveEditPage } from "./services/VetsGraveForm";
import VetsGraveSearchPage from "./services/VetsGraveSearch";
// Currently we prefer to use inline styles wherever possible since all styles
// are component-specific, however, there are some styles which MUST be added to a
// traditional stylesheet and thus are imported here.
import "./App.css";
import { FulfillmentsAdminPage, FulfillmentsConfigPage } from "./Fulfillments";
import OrdersPage, { OrderDetailPage } from "./Orders";
import { SearchFieldBoostsPage } from "./SearchFieldBoosts";
import { WWIBonusRecord } from "./schemas/wwiBonus";
import {
  WWIServiceRecord,
  wwiServiceFreeformDateSpecs,
} from "./schemas/wwiService";
import { WWISolPhotoRecord } from "./schemas/wwiSolPhoto";
import { BirthRecordReleasePage } from "./services/BirthRecordRelease";
import { CatalogueItemDetailPage } from "./services/CatalogueItemDetail";
import CollectionsImageResConfigPage, {
  CollectionsImageResConfigHistoryPage,
} from "./services/CatalogueItemImgConfig";
import CollectionsMediaPage from "./services/CatalogueItemMedia";
import CatalogueItemSearchPage from "./services/CatalogueItemSearch";
import { CollectionsSearchConfigPage } from "./services/CatalogueItemSearchConfig";
import CommentAdminPage from "./services/CommentAdmin";
import { CommentBlockedEmailsPage } from "./services/CommentBlockedEmails";
import { CommentFeedPage } from "./services/CommentFeed";
import { CommentSubsUrlPatMgmtPage } from "./services/CommentSubsUrlPatMgmt";
import { DeathRecordBulkEditPage } from "./services/DeathRecordBulkEdit";
import { WWIBonusDetailPage } from "./services/WWIBonusDetail";
import { WWIBonusAddPage, WWIBonusEditPage } from "./services/WWIBonusForm";
import WWIBonusSearchPage from "./services/WWIBonusSearch";
import { WWIServiceDetailPage } from "./services/WWIServiceDetail";
import {
  WWIServiceAddPage,
  WWIServiceEditPage,
} from "./services/WWIServiceForm";
import WWIServiceSearchPage from "./services/WWIServiceSearch";
import { WWISolPhotoDetailPage } from "./services/WWISolPhotoDetail";
import WWISolPhotoSearchPage from "./services/WWISolPhotoSearch";

export default function App() {
  const navigate = useNavigate();
  // Auth state/mgmt/display:
  const [firebaseIdToken, setFirebaseIdToken] = useState<string | null>();
  const [bannerActionLoading, setBannerActionLoading] =
    useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data: currentUserData } = useQuery(
    ["currentUserData", firebaseIdToken],
    async () =>
      firebaseIdToken ? await signInWithFirebaseIdToken(firebaseIdToken) : null,
    // TODO: Make user stale time configurable at root.
    { staleTime: 1000 * 60 * 20 }, // 20 minutes to undercut access token TTL
  );

  useEffect(() => {
    const unregisterAuthObserver = getAuth().onIdTokenChanged(
      async (user: FirebaseUser | null) => {
        const idToken = await user?.getIdToken();
        setFirebaseIdToken(idToken || null);
        queryClient.invalidateQueries(["currentUserData"]);
      },
    );
    return () => unregisterAuthObserver();
  }, [queryClient, firebaseIdToken]);

  const userHasPermissions = (
    ...requiredPermissions: PermissionId[]
  ): boolean => {
    return currentUserData
      ? hasPermissions(currentUserData, ...requiredPermissions)
      : false;
  };

  const Protected = ({
    isAuthorized,
    children = null,
  }: {
    isAuthorized: boolean;
    children?: JSX.Element | null;
  }) =>
    isAuthorized ? (
      children
    ) : (
      <NotAuthorized currentUserData={currentUserData} />
    );

  const signIn = (
    <Layout.Section>
      <div style={{ marginTop: "32px" }}>
        <Stack align="center">
          <Heading>MNHS Vital Records</Heading>
          {firebaseIdToken === null ? <SignIn /> : <Spinner />}
        </Stack>
      </div>
    </Layout.Section>
  );

  const handleSignOut = () => {
    getAuth().signOut();
    window.location.reload();
  };

  // Search state/mgmt:
  const birthRecordSearchState = GenPersistentSearchState<BirthRecord>(
    birthRecordFreeformDateSpecs,
  );
  const deathRecordSearchState = GenPersistentSearchState<DeathRecord>(
    deathRecordFreeformDateSpecs,
  );
  const goldStarSearchState = GenPersistentSearchState<GoldStarRecord>(
    goldStarFreeformDateSpecs,
  );
  const vetsGraveSearchState = GenPersistentSearchState<VetsGraveRecord>(
    vetsGraveFreeformDateSpecs,
  );
  const censusSearchState = GenPersistentSearchState<CensusRecord>(
    censusFreeformDateSpecs,
  );
  const censusPageSearchState = GenPersistentSearchState<CensusPage>();
  const wwiServiceSearchState = GenPersistentSearchState<WWIServiceRecord>(
    wwiServiceFreeformDateSpecs,
  );
  const wwiBonusSearchState = GenPersistentSearchState<WWIBonusRecord>();
  const wwiSolPhotoSearchState = GenPersistentSearchState<WWISolPhotoRecord>();
  const catalogueItemSearchState = GenPersistentSearchState<CatalogueItem>(
    catalogueItemFreeformDateSpecs,
  );
  const findaidSearchState = GenPersistentSearchState<FindingAid>();

  useEffect(() => {
    if (currentUserData) {
      birthRecordSearchState.updateFromContextualPrefs(
        currentUserData.prefs.contextualPrefs.birthRecords,
      );
      deathRecordSearchState.updateFromContextualPrefs(
        currentUserData.prefs.contextualPrefs.deathRecords,
      );
    }
    // eslint doesn't like that we're creating the persistent search states
    // and referencing them in this useEffect without making them a dep, but that
    // seems to be the only way to get this to function exactly right.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData]);

  // Use this to secure pages that don't (yet?) have a specific permission locking
  // them, but which should only be accessible to the sysadmin.
  const isSysAdmin = (currentUserData?.user.roleIds || []).includes(
    SYSADMIN_ROLE_ID,
  );

  const canUpdateUserProfile = userHasPermissions("update_user_profile");

  const canSeeManageAuth = userHasPermissions("manage_authorization");

  const canSeeAdminAddEditUsers = userHasPermissions("add_edit_users");

  const canManageEmailAutos = userHasPermissions("manage_email_automation");

  const canSeeAdminAddEditRemoveRoles = userHasPermissions(
    "add_edit_remove_roles",
  );

  const canSeeComments =
    userHasPermissions("view_public_comments") ||
    userHasPermissions("view_unpublished_comments");

  const canSeeCmtSubUrlPats = userHasPermissions("view_cmt_sub_url_pats");

  const canSeeCmtBlockedEmails = userHasPermissions("view_cmt_blocked_emails");

  const canViewFulfillmentConfig = userHasPermissions(
    "view_fulfillment_config",
  );
  const canManageFulfillments = userHasPermissions("manage_fulfillments");

  const canSeeBirthRecords =
    userHasPermissions("view_public_birth_records") ||
    userHasPermissions("view_confidential_birth_records") ||
    userHasPermissions("view_unpublished_birth_records");
  const canSeeBirthRecordsAddEdit = userHasPermissions(
    "add_edit_publish_remove_birth_records",
  );
  const canSeeBirthRecordBoosts = userHasPermissions(
    "edit_birth_record_search_field_boosts",
  );
  const canBulkEditBirthRecords = userHasPermissions(
    "bulk_update_birth_records",
  );

  const canSeeDeathRecords =
    userHasPermissions("view_public_death_records") ||
    userHasPermissions("view_confidential_death_records") ||
    userHasPermissions("view_unpublished_death_records");
  const canSeeDeathRecordsAddEdit = userHasPermissions(
    "add_edit_publish_remove_death_records",
  );
  const canSeeDeathRecordBoosts = userHasPermissions(
    "edit_death_record_search_field_boosts",
  );
  const canBulkEditDeathRecords = userHasPermissions(
    "bulk_update_death_records",
  );

  const canSeeGoldStarRollRecords = userHasPermissions(
    "view_gold_star_roll_records",
  );
  const canSeeGoldStarRollRecordsAddEdit = userHasPermissions(
    "add_edit_remove_gold_star_roll_records",
  );
  const canSeeGoldStarRollRecordBoosts = userHasPermissions(
    "edit_gold_star_roll_search_field_boosts",
  );
  //   const canBulkEditGoldStarRollRecords = userHasPermissions(
  //     "bulk_update_gold_star_roll_records"
  //   );

  const canSeeVetsGraveRecords = userHasPermissions("view_vets_grave_records");
  const canSeeVetsGraveRecordsAddEdit = userHasPermissions(
    "add_edit_remove_vets_grave_records",
  );
  const canSeeVetsGraveRecordBoosts = userHasPermissions(
    "edit_vets_graves_record_search_field_boosts",
  );
  //   const canBulkEditVetsGraveRecords = userHasPermissions(
  //     "bulk_update_vets_grave_records"
  //   );

  const canSeeCensusRecords = userHasPermissions("view_public_census_records");
  const canSeeCensusAddEdit = userHasPermissions(
    "add_edit_remove_census_records",
  );
  const canSeeCensusBoosts = userHasPermissions(
    "edit_census_record_search_field_boosts",
  );
  //   const canBulkEditCensusRecords = userHasPermissions(
  //     "bulk_update_census_records"
  //   );

  const canSeeCatalogueItems = userHasPermissions(
    "view_public_collections_items",
  );
  const canSeeEditCollectionsItems = userHasPermissions(
    "edit_remove_collections_items",
  );
  const canSeeCatalogueItemsBoosts = userHasPermissions(
    "edit_collections_item_search_field_boosts",
  );

  const canSeeWWIServiceRecords = userHasPermissions(
    "view_public_wwi_service_records",
  );
  const canSeeWWIServiceRecordsAddEdit = userHasPermissions(
    "add_edit_remove_wwi_service_records",
  );
  const canSeeWWIServiceRecordBoosts = userHasPermissions(
    "edit_wwi_service_record_search_field_boosts",
  );

  const canSeeWWIBonusRecords = userHasPermissions(
    "view_public_wwi_bonus_records",
  );
  const canSeeWWIBonusRecordsAddEdit = userHasPermissions(
    "add_edit_remove_wwi_bonus_records",
  );
  const canSeeWWIBonusRecordBoosts = userHasPermissions(
    "edit_wwi_bonus_record_search_field_boosts",
  );

  const canSeeWWISolPhotos = userHasPermissions(
    "view_public_wwi_soldiers_photos_records",
  );
  const canSeeWWISolPhotoBoosts = userHasPermissions(
    "edit_wwi_soldiers_photos_record_search_field_boosts",
  );

  const canSeeFindAidsAddEdit = userHasPermissions("add_edit_remove_find_aids");

  const adminRoutes = currentUserData && (
    <Route path="admin">
      <Route
        path="email-autos"
        element={
          <Protected isAuthorized={canManageEmailAutos}>
            <EmailAutomationPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route path="fulfillment">
        <Route
          index
          element={
            <Protected
              isAuthorized={canManageFulfillments || canViewFulfillmentConfig}
            >
              <FulfillmentsAdminPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="config"
          element={
            <Protected isAuthorized={canViewFulfillmentConfig}>
              <FulfillmentsConfigPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route path="orders">
          <Route
            index
            element={
              <Protected isAuthorized={canManageFulfillments}>
                <OrdersPage currentUserData={currentUserData} />
              </Protected>
            }
          />
          <Route
            path=":uuid"
            element={
              <Protected isAuthorized={canManageFulfillments}>
                <OrderDetailPage currentUserData={currentUserData} />
              </Protected>
            }
          />
        </Route>
      </Route>
    </Route>
  );

  const authRoutes = currentUserData && (
    <Route path="auth">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeManageAuth}>
            <ManageAuthorization currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route path="roles">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeAdminAddEditRemoveRoles}>
              <RolesPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="add"
          element={
            <Protected isAuthorized={canSeeAdminAddEditRemoveRoles}>
              <NewRoleFormPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route path=":uuid">
          <Route
            index
            element={
              <Protected isAuthorized={canSeeAdminAddEditRemoveRoles}>
                <RoleFormPage currentUserData={currentUserData} />
              </Protected>
            }
          />
        </Route>
      </Route>
      <Route path="users">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeManageAuth}>
              <ManageAuthorization currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="new"
          element={
            <Protected isAuthorized={canSeeAdminAddEditUsers}>
              <UserAddPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route path=":uuid">
          <Route
            index
            element={
              <Protected
                isAuthorized={canSeeAdminAddEditUsers || canSeeManageAuth}
              >
                <UserDetailPage currentUserData={currentUserData} />
              </Protected>
            }
          />
          <Route
            path="edit"
            element={
              <Protected isAuthorized={canSeeAdminAddEditUsers}>
                <UserEditPage currentUserData={currentUserData} />
              </Protected>
            }
          />
          <Route
            path="activity"
            element={
              <Protected
                isAuthorized={userHasPermissions("view_birth_record_journal")}
              >
                <JournalActivityPage currentUserData={currentUserData} />
              </Protected>
            }
          />
        </Route>
      </Route>
    </Route>
  );

  const commentRoutes = currentUserData && env.FEATURE_COMMENTS && (
    <Route path="comments">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeComments}>
            <CommentFeedPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route path="admin">
        <Route
          index
          element={<CommentAdminPage currentUserData={currentUserData} />}
        />
        <Route
          path="sub-urls"
          element={
            <Protected isAuthorized={canSeeCmtSubUrlPats}>
              <CommentSubsUrlPatMgmtPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="blocked-emails"
          element={
            <Protected isAuthorized={canSeeCmtBlockedEmails}>
              <CommentBlockedEmailsPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );

  const birthRecordRoutes = currentUserData && env.FEATURE_BIRTH_RECORDS && (
    <Route path="birth-records">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeBirthRecords}>
            <BirthRecordSearchPage
              currentUserData={currentUserData}
              persistentState={birthRecordSearchState}
              contextualPrefs={
                currentUserData.prefs.contextualPrefs.birthRecords
              }
            />
          </Protected>
        }
      />
      <Route
        path="add"
        element={
          <Protected isAuthorized={canSeeBirthRecordsAddEdit}>
            <BirthRecordAddPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      {env.FEATURE_BULK_UPDATE && (
        <Route path="bulk">
          <Route
            path="dash"
            element={
              <Protected isAuthorized={canBulkEditBirthRecords}>
                <BulkEditDashboard
                  currentUserData={currentUserData}
                  title="Birth Record Bulk Editing"
                  baseEndpoint="birth-records"
                  searchUrl="/rma/birth-records/"
                />
              </Protected>
            }
          />
          <Route
            path="edit"
            element={
              <Protected isAuthorized={canBulkEditBirthRecords}>
                <BirthRecordBulkEditPage
                  currentUserData={currentUserData}
                  searchState={birthRecordSearchState}
                />
              </Protected>
            }
          />
        </Route>
      )}
      <Route
        path="boosts"
        element={
          <Protected isAuthorized={canSeeBirthRecordBoosts}>
            <SearchFieldBoostsPage
              currentUserData={currentUserData}
              title="Birth Record"
              dataset="birth-records"
            />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeBirthRecords}>
              <BirthRecordDetailPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="edit"
          element={
            <Protected
              isAuthorized={canSeeBirthRecords && canSeeBirthRecordsAddEdit}
            >
              <BirthRecordEditPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
      <Route
        path="annual-release"
        element={
          <Protected
            // Currently only the sysadmin has access to this page, purely because
            // collections staff aren't on-board yet with doing this yearly release
            // task themselves. To hide it from them this arbitrary sysadmin-only
            // permission is used.
            isAuthorized={isSysAdmin}
          >
            <BirthRecordReleasePage currentUserData={currentUserData} />
          </Protected>
        }
      />
    </Route>
  );

  const deathRecordRoutes = currentUserData && (
    <Route path="death-records">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeDeathRecords}>
            <DeathRecordSearchPage
              currentUserData={currentUserData}
              persistentState={deathRecordSearchState}
              contextualPrefs={
                currentUserData.prefs.contextualPrefs.deathRecords
              }
            />
          </Protected>
        }
      />
      <Route
        path="add"
        element={
          <Protected isAuthorized={canSeeDeathRecordsAddEdit}>
            <DeathRecordAddPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      {env.FEATURE_BULK_UPDATE && (
        <Route path="bulk">
          <Route
            path="dash"
            element={
              <Protected isAuthorized={canBulkEditDeathRecords}>
                <BulkEditDashboard
                  currentUserData={currentUserData}
                  title="Death Record Bulk Editing"
                  baseEndpoint="death-records"
                  searchUrl="/rma/death-records/"
                />
              </Protected>
            }
          />
          <Route
            path="edit"
            element={
              <Protected isAuthorized={canBulkEditDeathRecords}>
                <DeathRecordBulkEditPage
                  currentUserData={currentUserData}
                  searchState={deathRecordSearchState}
                />
              </Protected>
            }
          />
        </Route>
      )}
      <Route
        path="boosts"
        element={
          <Protected isAuthorized={canSeeDeathRecordBoosts}>
            <SearchFieldBoostsPage
              currentUserData={currentUserData}
              title="Death Record"
              dataset="death-records"
            />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeDeathRecords}>
              <DeathRecorDetailPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="edit"
          element={
            <Protected
              isAuthorized={canSeeDeathRecords && canSeeDeathRecordsAddEdit}
            >
              <DeathRecordEditPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );

  const goldStarRoutes = currentUserData && (
    <Route path="gold-star">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeGoldStarRollRecords}>
            <GoldStarSearchPage
              currentUserData={currentUserData}
              persistentState={goldStarSearchState}
              contextualPrefs={currentUserData.prefs.contextualPrefs.goldStar}
            />
          </Protected>
        }
      />
      <Route
        path="add"
        element={
          <Protected isAuthorized={canSeeGoldStarRollRecordsAddEdit}>
            <GoldStarAddPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route
        path="boosts"
        element={
          <Protected isAuthorized={canSeeGoldStarRollRecordBoosts}>
            <SearchFieldBoostsPage
              currentUserData={currentUserData}
              title="Gold Star Roll"
              dataset="gold-star"
            />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeGoldStarRollRecords}>
              <GoldStarDetailPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="edit"
          element={
            <Protected
              isAuthorized={
                canSeeGoldStarRollRecords && canSeeGoldStarRollRecordsAddEdit
              }
            >
              <GoldStarEditPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );

  const vetsGraveRoutes = currentUserData && (
    <Route path="vets-graves">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeVetsGraveRecords}>
            <VetsGraveSearchPage
              currentUserData={currentUserData}
              persistentState={vetsGraveSearchState}
              contextualPrefs={currentUserData.prefs.contextualPrefs.vetsGraves}
            />
          </Protected>
        }
      />
      <Route
        path="add"
        element={
          <Protected isAuthorized={canSeeVetsGraveRecordsAddEdit}>
            <VetsGraveAddPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route
        path="boosts"
        element={
          <Protected isAuthorized={canSeeVetsGraveRecordBoosts}>
            <SearchFieldBoostsPage
              currentUserData={currentUserData}
              title="Veteran's Graves Record"
              dataset="vets-graves"
            />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeVetsGraveRecords}>
              <VetsGraveDetailPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="edit"
          element={
            <Protected
              isAuthorized={
                canSeeVetsGraveRecords && canSeeVetsGraveRecordsAddEdit
              }
            >
              <VetsGraveEditPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );

  const wwiServiceRoutes = currentUserData && (
    <Route path="wwi-service">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeWWIServiceRecords}>
            <WWIServiceSearchPage
              currentUserData={currentUserData}
              persistentState={wwiServiceSearchState}
              contextualPrefs={currentUserData.prefs.contextualPrefs.wwiService}
            />
          </Protected>
        }
      />
      <Route
        path="add"
        element={
          <Protected isAuthorized={canSeeWWIServiceRecordsAddEdit}>
            <WWIServiceAddPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route
        path="boosts"
        element={
          <Protected isAuthorized={canSeeWWIServiceRecordBoosts}>
            <SearchFieldBoostsPage
              currentUserData={currentUserData}
              title="WWI Service Records"
              dataset="wwi-service"
            />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeWWIServiceRecords}>
              <WWIServiceDetailPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="edit"
          element={
            <Protected
              isAuthorized={
                canSeeWWIServiceRecords && canSeeWWIServiceRecordsAddEdit
              }
            >
              <WWIServiceEditPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );

  const wwiBonusRoutes = currentUserData && (
    <Route path="wwi-bonuses">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeWWIServiceRecords}>
            <WWIBonusSearchPage
              currentUserData={currentUserData}
              persistentState={wwiBonusSearchState}
              contextualPrefs={currentUserData.prefs.contextualPrefs.wwiBonuses}
            />
          </Protected>
        }
      />
      <Route
        path="add"
        element={
          <Protected isAuthorized={canSeeWWIBonusRecordsAddEdit}>
            <WWIBonusAddPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route
        path="boosts"
        element={
          <Protected isAuthorized={canSeeWWIServiceRecordBoosts}>
            <SearchFieldBoostsPage
              currentUserData={currentUserData}
              title="WWI Service Records"
              dataset="wwi-service"
            />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeWWIBonusRecords}>
              <WWIBonusDetailPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="edit"
          element={
            <Protected
              isAuthorized={
                canSeeWWIBonusRecords && canSeeWWIBonusRecordsAddEdit
              }
            >
              <WWIBonusEditPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );

  const wwiSolPhotoRoutes = currentUserData && (
    <Route path="wwi-soldiers-photos">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeWWISolPhotos}>
            <WWISolPhotoSearchPage
              currentUserData={currentUserData}
              persistentState={wwiSolPhotoSearchState}
              contextualPrefs={
                currentUserData.prefs.contextualPrefs.wwiSolPhotos
              }
            />
          </Protected>
        }
      />
      <Route
        path="boosts"
        element={
          <Protected isAuthorized={canSeeWWISolPhotoBoosts}>
            <SearchFieldBoostsPage
              currentUserData={currentUserData}
              title="WWI Soldiers Photos"
              dataset="wwi-soldiers-photos"
            />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeWWISolPhotos}>
              <WWISolPhotoDetailPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );
  const censusPageRoutes = currentUserData && (
    <Route path="page">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeCensusRecords}>
            <CensusPageSearchPage
              currentUserData={currentUserData}
              persistentState={censusPageSearchState}
              contextualPrefs={currentUserData.prefs.contextualPrefs.census}
            />
          </Protected>
        }
      />
      <Route
        path="add"
        element={
          <Protected isAuthorized={canSeeCensusAddEdit}>
            <CensusPageAddPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route
        path="boosts"
        element={
          <Protected isAuthorized={canSeeCensusBoosts}>
            <SearchFieldBoostsPage
              currentUserData={currentUserData}
              title="Census Page Record"
              dataset="census-pages"
            />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeCensusRecords}>
              <CensusPageDetailPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="edit"
          element={
            <Protected
              isAuthorized={canSeeCensusRecords && canSeeCensusAddEdit}
            >
              <CensusPageEditPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );

  const censusRoutes = currentUserData && (
    <Route path="census">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeCensusRecords}>
            <CensusSearchPage
              currentUserData={currentUserData}
              persistentState={censusSearchState}
              contextualPrefs={currentUserData.prefs.contextualPrefs.census}
            />
          </Protected>
        }
      />
      <Route
        path="add"
        element={
          <Protected isAuthorized={canSeeCensusAddEdit}>
            <CensusAddPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      {censusPageRoutes}
      <Route path="media">
        <Route path=":uuid">
          <Route
            index
            element={
              <Protected isAuthorized={canSeeCensusRecords}>
                <CensusMediaPage currentUserData={currentUserData} />
              </Protected>
            }
          />
        </Route>
      </Route>
      <Route
        path="boosts"
        element={
          <Protected isAuthorized={canSeeCensusBoosts}>
            <SearchFieldBoostsPage
              currentUserData={currentUserData}
              title="Census Record"
              dataset="census"
            />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeCensusRecords}>
              <CensusDetailPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="edit"
          element={
            <Protected
              isAuthorized={canSeeCensusRecords && canSeeCensusAddEdit}
            >
              <CensusEditPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );

  const collectionsRoutes = currentUserData && env.FEATURE_COLLECTIONS && (
    <Route path="collections">
      <Route path="catalogue-items">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeCatalogueItems}>
              <CatalogueItemSearchPage
                currentUserData={currentUserData}
                persistentState={catalogueItemSearchState}
                contextualPrefs={
                  currentUserData.prefs.contextualPrefs.collections
                }
              />
            </Protected>
          }
        />
        <Route
          path="boosts"
          element={
            <Protected isAuthorized={canSeeCatalogueItemsBoosts}>
              <SearchFieldBoostsPage
                currentUserData={currentUserData}
                title="CMS Item"
                dataset="catalogue-items"
              />
            </Protected>
          }
        />
        <Route path="image-config">
          <Route
            index
            element={
              <Protected isAuthorized={canSeeEditCollectionsItems}>
                <CollectionsImageResConfigPage
                  currentUserData={currentUserData}
                />
              </Protected>
            }
          />
          <Route
            path="history"
            element={
              <Protected isAuthorized={canSeeEditCollectionsItems}>
                <CollectionsImageResConfigHistoryPage
                  currentUserData={currentUserData}
                />
              </Protected>
            }
          />
        </Route>
        <Route path=":uuid">
          <Route
            index
            element={
              <Protected isAuthorized={canSeeCatalogueItems}>
                <CatalogueItemDetailPage currentUserData={currentUserData} />
              </Protected>
            }
          />
        </Route>
      </Route>
      <Route path="media">
        <Route
          path=":hash"
          element={
            <Protected isAuthorized={canSeeCatalogueItems}>
              <CollectionsMediaPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
      <Route
        path="search-config"
        element={
          <Protected isAuthorized={canSeeEditCollectionsItems}>
            <CollectionsSearchConfigPage currentUserData={currentUserData} />
          </Protected>
        }
      />
    </Route>
  );

  const findAidRoutes = currentUserData && (
    <Route path="finding-aids">
      <Route
        index
        element={
          <Protected isAuthorized={canSeeFindAidsAddEdit}>
            <FindAidSearchPage
              currentUserData={currentUserData}
              persistentState={findaidSearchState}
              contextualPrefs={{
                useAdvSearchToggle: false,
                useNavMode: false,
                useEditMode: false,
              }}
            />
          </Protected>
        }
      />
      <Route
        path="add"
        element={
          <Protected isAuthorized={canSeeFindAidsAddEdit}>
            <FindaidAddPage currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route path=":uuid">
        <Route
          index
          element={
            <Protected isAuthorized={canSeeFindAidsAddEdit}>
              <FindaidEditPage currentUserData={currentUserData} />
            </Protected>
          }
        />
        <Route
          path="preview"
          element={
            <Protected isAuthorized={canSeeFindAidsAddEdit}>
              <FindAidPreviewPage currentUserData={currentUserData} />
            </Protected>
          }
        />
      </Route>
    </Route>
  );

  const routes = currentUserData && (
    <Routes>
      <Route index element={<Home currentUserData={currentUserData} />} />
      <Route
        path="user-profile"
        element={
          <Protected isAuthorized={canUpdateUserProfile}>
            <UserProfilePage currentUserData={currentUserData} />
          </Protected>
        }
      />
      <Route
        path="api"
        element={<UserAPICredsPage currentUserData={currentUserData} />}
      />
      {authRoutes}
      {adminRoutes}
      {commentRoutes}
      {birthRecordRoutes}
      {deathRecordRoutes}
      {goldStarRoutes}
      {vetsGraveRoutes}
      {censusRoutes}
      {wwiServiceRoutes}
      {wwiBonusRoutes}
      {wwiSolPhotoRoutes}
      {collectionsRoutes}
      {findAidRoutes}
    </Routes>
  );

  const topbar = (
    <TopBar
      user={currentUserData}
      handleSignout={handleSignOut}
      userProfileAction={
        canUpdateUserProfile ? () => navigate("/rma/user-profile") : undefined
      }
    />
  );

  const navigation = (
    <Navigation
      showAdminRoleMgmt={canSeeAdminAddEditRemoveRoles}
      showAuth={canSeeManageAuth}
      showEmailAutos={canManageEmailAutos}
      comments={env.FEATURE_COMMENTS && canSeeComments}
      showFulfillmentMgmt={
        env.FEATURE_AUTO_FULFILLMENT &&
        (canManageFulfillments || canViewFulfillmentConfig)
      }
      birthRecords={
        env.FEATURE_BIRTH_RECORDS
          ? {
              showSearch: canSeeBirthRecords,
              showAdd: canSeeBirthRecordsAddEdit,
              showBulkDash: env.FEATURE_BULK_UPDATE && canBulkEditBirthRecords,
              showBoostMgr: canSeeBirthRecordBoosts,
              showAnnualRelease: isSysAdmin,
            }
          : {}
      }
      deathRecords={
        env.FEATURE_DEATH_RECORDS
          ? {
              showSearch: canSeeDeathRecords,
              showAdd: canSeeDeathRecordsAddEdit,
              showBulkDash: env.FEATURE_BULK_UPDATE && canBulkEditDeathRecords,
              showBoostMgr: canSeeDeathRecordBoosts,
            }
          : {}
      }
      goldStarRecords={
        env.FEATURE_GOLD_STAR
          ? {
              showSearch: canSeeGoldStarRollRecords,
              showAdd: canSeeGoldStarRollRecordsAddEdit,
              showBoostMgr: canSeeGoldStarRollRecordBoosts,
            }
          : {}
      }
      vetsGraves={
        env.FEATURE_VETS_GRAVE
          ? {
              showSearch: canSeeVetsGraveRecords,
              showAdd: canSeeVetsGraveRecordsAddEdit,
              showBoostMgr: canSeeVetsGraveRecordBoosts,
            }
          : {}
      }
      census={
        env.FEATURE_CENSUS
          ? {
              showSearch: canSeeCensusRecords,
              showAdd: canSeeCensusAddEdit,
              showBoostMgr: canSeeCensusBoosts,
            }
          : {}
      }
      collections={
        env.FEATURE_COLLECTIONS
          ? {
              showSearch: canSeeCatalogueItems,
              showBoostMgr: canSeeCatalogueItemsBoosts,
              showSearchConfig: canSeeEditCollectionsItems,
            }
          : {}
      }
      wwiService={
        env.FEATURE_WWI_SERVICE
          ? {
              showSearch: canSeeWWIServiceRecords,
              showAdd: canSeeWWIServiceRecordsAddEdit,
              showBoostMgr: canSeeWWIServiceRecordBoosts,
            }
          : {}
      }
      wwiBonuses={
        env.FEATURE_WWI_BONUSES
          ? {
              showSearch: canSeeWWIBonusRecords,
              showAdd: canSeeWWIBonusRecordsAddEdit,
              showBoostMgr: canSeeWWIBonusRecordBoosts,
            }
          : {}
      }
      wwiSolPhotos={
        env.FEATURE_WWI_SOL_PHOTOS
          ? {
              showSearch: canSeeWWISolPhotos,
              showBoostMgr: canSeeWWISolPhotoBoosts,
            }
          : {}
      }
      findAids={
        env.FEATURE_FIND_AIDS
          ? {
              showSearch: canSeeFindAidsAddEdit,
              showAdd: canSeeFindAidsAddEdit,
            }
          : {}
      }
    />
  );

  //   const colorScheme = currentUserData
  //     ? currentUserData.prefs.useNightMode
  //       ? "dark"
  //       : "light"
  //     : "dark";

  const logo = {
    topBarSource:
      "https://www.mnhs.org/sites/default/files/media/news/mnhs_logo_w.png",
    // topBarSource: `https://www.mnhs.org/sites/default/files/media/news/mnhs_logo${
    //   colorScheme === "dark" ? "_w" : ""
    // }.png`,
    accessibilityLabel: "MNHS Vital Records App",
    url: "/rma/",
  };

  const verify = currentUserData && !currentUserData.emailVerified && (
    <VerificationBanner
      loading={bannerActionLoading}
      onAction={async () => {
        setBannerActionLoading(true);
        await requestEmailVerification(currentUserData.accessToken);
        setBannerActionLoading(false);
      }}
    />
  );

  return (
    <>
      <Frame navigation={navigation} topBar={topbar} logo={logo}>
        <Page fullWidth>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => window.location.reload()}
          >
            {verify}
            {routes || signIn}
          </ErrorBoundary>
        </Page>
      </Frame>
    </>
  );
}
