import { ReactNode, useState } from "react";
import {
  FilterParamLike,
  SearchFilterFields,
  SearchSortFields,
  SearchSortParams,
} from "./search/searchutils";
import { CurrentUserData, Labels, PagedResults, Sort } from "./schemas/core";
import { UseQueryResult } from "react-query";
import SortParamDisplay from "./search/SortParamDisplay";
import FilterParamDisplay from "./search/FilterParamDisplay";
import { Divider, ResourceItem, ResourceList, Spinner } from "@shopify/polaris";
import Pagination from "./search/Pagination";
import Stack from "../shared/Stack";
import { Heading } from "../shared/TextComponents";

interface QueryHandlerProps<T> {
  currentUserData: CurrentUserData;
  queryHook: (
    accessToken: string,
    pageNum: number,
    pageSize: number,
    sorts?: Sort[],
    filters?: FilterParamLike[],
  ) => UseQueryResult<PagedResults<T>, any>;
  sortFieldsSpec: SearchSortFields<T>;
  filterFieldsSpec: SearchFilterFields;
  labels: Labels<T>;
  renderResultItem: (item: T) => ReactNode;
  renderResultItemOnClick?: (id?: string) => void;
  renderItemAccessibilityLabel: (item: T) => string;
  heading?: string;
  defaultSorts?: SearchSortParams<T>;
  defaultFilters?: FilterParamLike[];
}

export function QueryHandler<T extends { id: string }>({
  currentUserData,
  queryHook,
  sortFieldsSpec,
  filterFieldsSpec,
  labels,
  renderResultItem,
  renderResultItemOnClick,
  renderItemAccessibilityLabel,
  heading = "Query Results",
  defaultSorts,
  defaultFilters,
}: QueryHandlerProps<T>) {
  const [page, setPage] = useState(1);
  const [sorts, setSorts] = useState<SearchSortParams<T>>(defaultSorts || {});
  const [filters, setFilters] = useState<FilterParamLike[]>(
    defaultFilters || [],
  );

  const {
    data: queryResults,
    isLoading,
    isRefetching,
  } = queryHook(
    currentUserData.accessToken,
    page,
    50,
    Object.values(sorts),
    filters,
  );

  const sortDisplay = (
    <SortParamDisplay
      sorts={sorts}
      setSorts={setSorts}
      sortFields={sortFieldsSpec}
      labels={labels}
    />
  );

  const filterDisplay = (
    <FilterParamDisplay
      filters={filters}
      setFilters={setFilters}
      filterFields={filterFieldsSpec}
      disallowEdit
      // Disallowing conjuncts for now since the API can't handle them atm.
      disallowConjuncts
    />
  );

  const renderItem = (item: T): ReactNode => (
    <ResourceItem
      id={item.id}
      accessibilityLabel={renderItemAccessibilityLabel(item)}
      onClick={renderResultItemOnClick ? renderResultItemOnClick : () => {}}
    >
      {renderResultItem(item)}
    </ResourceItem>
  );

  const pagination = (
    <Pagination
      results={queryResults}
      setPage={setPage}
      queryRefetching={isRefetching}
    />
  );

  const resultsList = queryResults && (
    <ResourceList
      resourceName={{ singular: "item", plural: "items" }}
      items={queryResults.results}
      renderItem={renderItem}
    />
  );

  const spinner = isLoading && (
    <Stack direction="row" justify="center">
      <Spinner />
    </Stack>
  );

  return (
    <Stack>
      <Heading>{heading}</Heading>
      <Divider />
      {sortDisplay}
      <Divider />
      {filterDisplay}
      <Divider />
      {pagination}
      {spinner}
      {resultsList}
      {pagination}
    </Stack>
  );
}
