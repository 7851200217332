import { Divider, Spinner } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import * as env from "../../env";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, Subheading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  BaseDetailCompProps,
  DetailFields,
  DetailSection,
  ListFieldStack,
} from "../Detail";
import {
  useBirthRecord,
  useBirthRecordEvents,
  useBirthRecordImage,
} from "../hooks/birthRecordHooks";
import EventHistory from "../journal/EventHistory";
import { BirthRecord, birthRecordLabels } from "../schemas/birthRecord";
import { CurrentUserData } from "../schemas/core";
import Skeleton from "../Skeleton";
import ZoomableImage from "../ZoomableImage";
import CommentSection from "./CommentSection";
import { getVitalRecordsDisplayName } from "./shared";
import ShopifyAddToCartBtn from "../utils/ShopifyAddToCartBtn";

export default function BirthRecordDetail(
  props: BaseDetailCompProps<BirthRecord>,
) {
  const birthRecord = props.record;

  const { data: imageFront, isLoading: imageLoading } = useBirthRecordImage(
    props.currentUserData.accessToken,
    birthRecord?.imageFrontSHA256,
  );

  const skeleton = !birthRecord && <Skeleton lines={16} />;

  const imageSpinner = imageLoading && !imageFront && <Spinner size="large" />;

  const image = !imageLoading && imageFront && (
    <>
      <Subheading>Front</Subheading>
      <ZoomableImage
        src={imageFront.content}
        alt="Front of birth certificate."
        fileName={birthRecord?.certificateId}
      />
    </>
  );

  const showEditBtn = hasPermissions(
    props.currentUserData,
    "add_edit_publish_remove_birth_records",
  );

  const heading = birthRecord && (
    <Stack spacing={1}>
      <Heading>{getVitalRecordsDisplayName(birthRecord)}</Heading>
      <Subheading>{birthRecord.id}</Subheading>
    </Stack>
  );

  const detail = birthRecord && (
    <Stack>
      {imageSpinner || image}
      <Divider />
      <DetailFields
        record={birthRecord}
        spec={{
          seriesCode: null,
          certificateId: null,
          firstName: null,
          middleName: null,
          familyName: null,
          suffix: null,
          mothersMaidenName: null,
          birthDateStart: { dateType: "date" },
          birthDateEnd: { dateType: "date" },
          county: null,
          confidentiality: null,
          legacyId: null,
        }}
        labels={birthRecordLabels}
      >
        <ListFieldStack
          label={birthRecordLabels["alternateNames"]}
          elements={birthRecord.alternateNames || []}
        />
      </DetailFields>
    </Stack>
  );

  return (
    <DetailSection
      onEditBtnClick={showEditBtn ? props.onEditBtnClick : undefined}
    >
      {heading}
      <EventHistory
        currentUserData={props.currentUserData}
        labels={birthRecordLabels}
        spec={{
          birthDateStart: { dateType: "date" },
          birthDateEnd: { dateType: "date" },
        }}
        viewJournalPerm="view_birth_record_journal"
        eventHistory={props.eventHistory}
      >
        <>{skeleton || detail}</>
      </EventHistory>
      {birthRecord && (
        <ShopifyAddToCartBtn
          record={birthRecord}
          domain="birth-records"
          productId={37761427767476}
          displayDate={birthRecord.birthDateStart}
          displayLocation={birthRecord.county}
        />
      )}
    </DetailSection>
  );
}

interface BirthRecordDetailPageProps {
  currentUserData: CurrentUserData;
}

export function BirthRecordDetailPage(props: BirthRecordDetailPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: birthRecord } = useBirthRecord(
    props.currentUserData.accessToken,
    uuid,
  );
  const { data: birthRecordEvents } = useBirthRecordEvents(
    props.currentUserData.accessToken,
    // This sneaks a conditional call into a react hook, which is necessary since
    // a user that can see a record might not be able to see the journal
    // and they'll receive a 403 when trying to call this function.
    // TODO: This maybe suggests something wrong with this permission structure?
    hasPermissions(props.currentUserData, "view_birth_record_journal")
      ? uuid
      : undefined,
  );

  const canSeeComments =
    hasPermissions(props.currentUserData, "view_public_comments") ||
    hasPermissions(props.currentUserData, "view_unpublished_comments");

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Stack>
        <Card>
          <BirthRecordDetail
            currentUserData={props.currentUserData}
            record={birthRecord}
            onEditBtnClick={() => navigate("./edit")}
            eventHistory={birthRecordEvents}
          />
        </Card>
        {uuid && env.FEATURE_COMMENTS && canSeeComments && (
          <CommentSection
            currentUserData={props.currentUserData}
            recordId={uuid}
            dataset="birthrecord"
            defaults={{
              userName: "People Records Admin",
              email: "peoplesearch@mnhs.org",
            }}
          />
        )}
      </Stack>
    </BreadcrumbPage>
  );
}
