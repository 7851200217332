import {
  Banner,
  Button,
  Collapsible,
  Icon,
  Layout,
  Page,
  ResourceItem,
  ResourceList,
  Spinner,
  Tooltip,
} from "@shopify/polaris";
import { StatusActiveIcon, XCircleIcon } from "@shopify/polaris-icons";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { RQUERY_STALE_TIME } from "../env";
import Card from "../shared/Card";
import Stack from "../shared/Stack";
import { Dot, Heading, PText, Subheading } from "../shared/TextComponents";
import Skeleton from "./Skeleton";
import { getUsers } from "./api/endpoints";
import UserFilterInput from "./auth/UserFilterInput";
import { RoleChecklist } from "./auth/UserForm";
import { usePermissionsByRole } from "./hooks/permissionHooks";
import { useRoles, useRolesByUser } from "./hooks/roleHooks";
import { useSetUserRoles } from "./hooks/userHooks";
import { CurrentUserData, RoleList } from "./schemas/core";
import { hasPermissions } from "./auth/authutils";

export default function ManageAuthorization({
  currentUserData,
}: {
  currentUserData: CurrentUserData;
}) {
  const [userFilter, setUserFilter] = useState<string>();
  const [userHasRole, setUserHasRole] = useState<string>();

  const navigate = useNavigate();
  const { data: users } = useQuery(
    ["users", currentUserData.accessToken, userFilter, userHasRole],
    async () => getUsers(currentUserData.accessToken, userFilter, userHasRole),
    { keepPreviousData: true, staleTime: RQUERY_STALE_TIME },
  );
  const { data: roles } = useRoles(currentUserData.accessToken);
  const { data: rolesByUser } = useRolesByUser(currentUserData.accessToken);
  const { data: permissionsByRole } = usePermissionsByRole(
    currentUserData.accessToken,
  );

  const mutation = useSetUserRoles();

  const [expandedUsers, setExpandedUsers] = useState<Record<string, boolean>>();
  const [rolesUpdateStatus, setRolesUpdateStatus] =
    useState<Record<string, undefined | "updating" | "success" | "error">>();

  const updateUserRoles = async (userUUID: string, roleUUIDS: string[]) => {
    setRolesUpdateStatus((hasRolesUpdateError) => ({
      ...hasRolesUpdateError,
      [userUUID]: "updating",
    }));

    await mutation.mutateAsync({
      accessToken: currentUserData.accessToken,
      userUUID: userUUID,
      roleUUIDs: roleUUIDS,
    });

    if (mutation.isError) {
      setRolesUpdateStatus((hasRolesUpdateError) => ({
        ...hasRolesUpdateError,
        [userUUID]: "error",
      }));
      console.error("Problem updating user roles", mutation.error);
    } else {
      setRolesUpdateStatus((hasRolesUpdateError) => ({
        ...hasRolesUpdateError,
        [userUUID]: "success",
      }));
    }
  };

  const toggleDetails = (userUUID: string) =>
    setExpandedUsers((expandedUsers) => ({
      ...expandedUsers,
      [userUUID]: !expandedUsers?.[userUUID],
    }));

  // The boolean-form of roles-by-user data was formerly a state object, but
  // I couldn't figure out how to get it to update on initial render after the
  // useQuery hooks finished firing, so now it is generated dynamically when
  // the ResourceItem for each user is generated.
  const generateRolesByUserBooleans = (
    userUUID: string,
    roles: RoleList,
    rolesByUser: Record<string, RoleList>,
  ): Record<string, boolean> => {
    const roleUUIDS = roles.map((r) => r.id);
    const userRoleList: RoleList | undefined = rolesByUser[userUUID];
    const userRoleUUIDs = userRoleList ? userRoleList.map((r) => r.id) : [];
    const result = roleUUIDS.reduce(
      (o, key) => ({ ...o, [key]: userRoleUUIDs.includes(key) }),
      {},
    );
    return result;
  };

  const skeleton = (!users || !roles || !rolesByUser || !permissionsByRole) && (
    <Skeleton lines={16} />
  );

  const userList = users && roles && rolesByUser && permissionsByRole && (
    <ResourceList
      resourceName={{ singular: "user", plural: "users" }}
      items={users.sort((a, b) => b.createDt.getTime() - a.createDt.getTime())}
      renderItem={(u) => {
        const userRoles = generateRolesByUserBooleans(u.id, roles, rolesByUser);
        return (
          <ResourceItem
            id={u.id}
            onClick={() => toggleDetails(u.id)}
            shortcutActions={[
              {
                content: "Toggle details",
                onAction: () => toggleDetails(u.id),
              },
            ]}
            accessibilityLabel={`View roles assigned to ${u.name}`}
          >
            {/* NOTE: Without the onClick here, details aren't toggled when
              the icon is clicked. */}
            <Stack direction="row">
              {(rolesUpdateStatus?.[u.id] === "updating" && (
                <Spinner
                  accessibilityLabel="Updating user's roles"
                  size="small"
                />
              )) ||
                (userRoles && Object.values(userRoles).includes(true) && (
                  <Tooltip content="One or more roles have been assigned to this user.">
                    <Icon source={StatusActiveIcon} tone="success" />
                  </Tooltip>
                )) || (
                  <Tooltip content="No roles have been assigned to this user.">
                    <Icon source={XCircleIcon} tone="subdued" />
                  </Tooltip>
                )}
              <PText weight="bold">
                {u.name} ({u.email})
              </PText>
            </Stack>

            {rolesUpdateStatus?.[u.id] === "error" && (
              <Banner tone="critical" title="Problem updating user roles">
                Please reload and try again. Contact FIXME if the problem
                persists.
              </Banner>
            )}

            <Collapsible
              open={!!expandedUsers?.[u.id]}
              id={`collapsible-${u.id}`}
              transition={{
                duration: "100ms",
                timingFunction: "ease-in-out",
              }}
              expandOnPrint
            >
              <div
                style={{ margin: "8px 0px" }}
                onClick={(e) => e.stopPropagation()}
              >
                {roles && (
                  <RoleChecklist
                    currentUserData={currentUserData}
                    title={
                      <div style={{ marginBottom: "8px" }}>
                        <Subheading>
                          User ID: {u.id} {Dot} Last Login:{" "}
                          {u.lastLoginDt?.toLocaleString()}
                        </Subheading>
                      </div>
                    }
                    roles={roles}
                    selectedRoleIds={
                      userRoles
                        ? Object.keys(userRoles).filter((k) => userRoles[k])
                        : []
                    }
                    onChange={(roleUUIDs) => updateUserRoles(u.id, roleUUIDs)}
                    disabled={
                      rolesUpdateStatus?.[u.id] === "updating" ||
                      rolesUpdateStatus?.[u.id] === "error"
                    }
                    genPerRoleHelpText={(role) =>
                      permissionsByRole?.[role.id]
                        .map((p) => p.name)
                        .join(" · ")
                    }
                    applyAuthority
                  />
                )}
              </div>
              <Stack direction="row" justify="flex-end">
                <Button onClick={() => navigate(`users/${u.id}`)}>
                  Details
                </Button>
                <Button onClick={() => navigate(`users/${u.id}/activity`)}>
                  Activity
                </Button>
              </Stack>
            </Collapsible>
          </ResourceItem>
        );
      }}
    />
  );

  return (
    <Page>
      <Layout.Section>
        <Card>
          <Stack>
            <Heading>Manage authorization</Heading>
            <UserFilterInput
              filterValue={userFilter}
              onFilterValueChange={(value) => setUserFilter(value)}
              hasRoleValue={userHasRole}
              onHasRoleSelect={(selected) => setUserHasRole(selected)}
              roles={roles}
              onClear={() => {
                setUserHasRole(undefined);
                setUserFilter(undefined);
              }}
            >
              {hasPermissions(currentUserData, "add_edit_users") && (
                <Button
                  variant="primary"
                  tone="success"
                  onClick={() => navigate("./users/new")}
                >
                  New User
                </Button>
              )}
            </UserFilterInput>
            {skeleton || userList}
          </Stack>
        </Card>
      </Layout.Section>
    </Page>
  );
}
