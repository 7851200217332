import {
  Button,
  Checkbox,
  InlineError,
  Select,
  Spinner,
  Form as PolarisForm,
} from "@shopify/polaris";
import { useState } from "react";
import Card, { NavCard } from "../shared/Card";
import Stack from "../shared/Stack";
import { Heading, PText, Subheading } from "../shared/TextComponents";
import { hasPermissions } from "./auth/authutils";
import BreadcrumbPage from "./BreadcrumbPage";
import { FormField } from "./Form";
import {
  RefreshFulfillmentParams,
  useFulfillmentActiveStatus,
  useFulfillmentConfigByDomain,
  useToggleFulfillmentActivation,
  useUpdateFulfillmentConfigByDomain,
} from "./hooks/fulfillmentHooks";
import { CurrentUserData } from "./schemas/core";
import {
  decodeFulfillmentConfigByDomain,
  encodeFulfillmentConfigStateByDomain,
  ExpPeriod,
  FulfillmentConfigByDomain,
  FulfillmentConfigState,
  FulfillmentConfigStateByDomain,
  FulfillmentRefreshInput,
  fulfillmentRefreshInputSchema,
} from "./schemas/fulfillment";
import { DefaultPageProps } from "./utils/shared";
import { UseMutationResult } from "react-query";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const expPeriodOpts: { label: string; value: ExpPeriod }[] = [
  { label: "minutes", value: "M" },
  { label: "hours", value: "h" },
  { label: "days", value: "d" },
  { label: "weeks", value: "w" },
];

function ShopifyFulfillmentStatusBtn({
  currentUserData,
}: {
  currentUserData: CurrentUserData;
}) {
  const { data: active, isLoading } = useFulfillmentActiveStatus(
    currentUserData.accessToken,
  );
  const mutation = useToggleFulfillmentActivation();

  const displayText = active
    ? "Shopify is currently connected to Frostbite for automated fulfillment," +
      " click to deactivate"
    : "Shopify is not currently connected to Frostbite for automated fulfillment," +
      " click to activate";

  const spinner = isLoading && <Spinner size="small" />;

  return (
    <Stack direction="row">
      {spinner}
      <Checkbox
        checked={active}
        onChange={async (value) => {
          await mutation.mutateAsync({
            accessToken: currentUserData.accessToken,
            activate: value,
          });
        }}
        disabled={isLoading || mutation.isLoading}
        label={
          isLoading
            ? "Checking Shopify for automated fulfillment status..."
            : displayText
        }
      />
    </Stack>
  );
}

interface FulfillmentRefreshFormProps {
  currentUserData: CurrentUserData;
  uuid: string;
  refreshInput: FulfillmentRefreshInput;
  mutation: UseMutationResult<void, any, RefreshFulfillmentParams, any>;
  direction?: "row" | "column";
  showExpOverride?: boolean;
}

export function FulfillmentRefreshForm({
  currentUserData,
  uuid,
  refreshInput,
  mutation,
  direction = "column",
  showExpOverride,
}: FulfillmentRefreshFormProps) {
  const { control, formState, handleSubmit, reset } =
    useForm<FulfillmentRefreshInput>({
      resolver: yupResolver(fulfillmentRefreshInputSchema),
      defaultValues: {
        emailOverride: refreshInput.emailOverride,
        expWindowOverride: refreshInput.expWindowOverride || {
          expValue: 0,
          expPeriod: "d",
        },
      },
    });

  const submit: SubmitHandler<FulfillmentRefreshInput> = async (
    inputData: FulfillmentRefreshInput,
  ) => {
    await mutation.mutateAsync(
      {
        accessToken: currentUserData.accessToken,
        uuid: uuid,
        refreshInput: {
          emailOverride: inputData.emailOverride,
          expWindowOverride:
            inputData.expWindowOverride?.expValue === 0
              ? null
              : inputData.expWindowOverride,
        },
      },
      {
        onSuccess: () => {
          return reset();
        },
      },
    );
  };

  const expPeriodInput = showExpOverride && (
    <>
      <Stack>
        <Controller
          name="expWindowOverride.expValue"
          control={control}
          render={({ field }) => (
            <FormField
              type="number"
              label="Expiration Value"
              labelHidden
              value={field.value ? field.value.toString() : "0"}
              onChange={field.onChange}
              disabled={mutation.isLoading}
              min={0}
            />
          )}
        />
        <InlineError
          fieldID="expWindowOverride.expValue"
          message={
            formState.errors.expWindowOverride?.expValue?.message?.toString() ||
            ""
          }
        />
      </Stack>
      <Controller
        name="expWindowOverride.expPeriod"
        control={control}
        render={({ field }) => (
          <Select
            label="Expiration Period"
            options={expPeriodOpts}
            value={field.value ? field.value.toString() : "d"}
            onChange={field.onChange}
            labelHidden
          />
        )}
      />
    </>
  );

  return (
    <PolarisForm
      onSubmit={handleSubmit(submit, (errors) => {
        console.log(errors);
      })}
    >
      <Stack direction={direction} align="flex-start">
        <Stack>
          <Controller
            name="emailOverride"
            control={control}
            render={({ field }) => (
              <FormField
                type="text"
                label="Email Overidde"
                labelHidden
                value={field.value}
                onChange={field.onChange}
                disabled={mutation.isLoading}
              />
            )}
          />
          <InlineError
            fieldID="emailOverride"
            message={
              formState.errors["emailOverride"]?.message?.toString() || ""
            }
          />
        </Stack>
        {expPeriodInput}
        <Button variant="primary" disabled={mutation.isLoading} submit>
          Refresh
        </Button>
        {mutation.isLoading && <Spinner size="small" />}
      </Stack>
    </PolarisForm>
  );
}

interface FulfillmentConfigEntryRowProps {
  label: string;
  stateKey: keyof FulfillmentConfigStateByDomain;
  configState: FulfillmentConfigStateByDomain;
  setConfigState: (config: FulfillmentConfigStateByDomain) => void;
  disabled?: boolean;
}

function FulfillmentConfigEntryRow({
  label,
  stateKey,
  configState,
  setConfigState,
  disabled,
}: FulfillmentConfigEntryRowProps) {
  const changeState = (k: keyof FulfillmentConfigState, value: string) => {
    let cfgState = { ...configState[stateKey] };
    if (k === "productId") {
      cfgState = { ...cfgState, productId: Number(value) };
    } else if (k === "expValue") {
      cfgState = { ...cfgState, expValue: Number(value) };
    } else {
      cfgState = { ...cfgState, expPeriod: value as ExpPeriod };
    }
    let newState = { ...configState, [stateKey]: cfgState };
    setConfigState(newState);
  };

  return (
    <Stack>
      <Subheading>{label}</Subheading>
      <Stack direction="row">
        <FormField
          type="number"
          label="Product ID"
          value={configState[stateKey].productId?.toString()}
          onChange={(value) => changeState("productId", value)}
          onClearButtonClick={() => {
            let newState = {
              ...configState,
              [stateKey]: { ...configState[stateKey], productId: null },
            };
            setConfigState(newState);
          }}
          disabled={disabled}
        />
        <FormField
          type="number"
          label="Expiration Window"
          value={configState[stateKey].expValue.toString()}
          onChange={(value) => changeState("expValue", value)}
          min={1}
          required
          disabled={disabled}
        />
        <Select
          label="Expiration Period"
          options={expPeriodOpts}
          value={configState[stateKey].expPeriod}
          onChange={(value) => changeState("expPeriod", value)}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
}

interface FulfillmentConfigEntryProps {
  configByDomain: FulfillmentConfigByDomain;
  currentUserData: CurrentUserData;
}

function FulfillmentConfigEntry({
  configByDomain,
  currentUserData,
}: FulfillmentConfigEntryProps) {
  const origConfigState = decodeFulfillmentConfigByDomain(configByDomain);
  const [configState, setConfigState] = useState(
    decodeFulfillmentConfigByDomain(configByDomain),
  );
  const [configChanged, setConfigChanged] = useState(false);

  const mutation = useUpdateFulfillmentConfigByDomain();

  const canManageFulfillmentConfig = hasPermissions(
    currentUserData,
    "manage_fulfillment_config",
  );

  return (
    <Stack>
      {Object.keys(configState).map((configKey) => (
        <FulfillmentConfigEntryRow
          stateKey={configKey as keyof FulfillmentConfigStateByDomain}
          label={configKey}
          disabled={!canManageFulfillmentConfig}
          configState={configState}
          setConfigState={(configState) => {
            setConfigChanged(true);
            setConfigState(configState);
          }}
        />
      ))}
      <Stack direction="row" justify="flex-end" align="center">
        {mutation.isLoading && <Spinner size="small" />}
        {mutation.isLoading && <PText>Saving config...</PText>}
        <Button
          onClick={() => {
            setConfigState(origConfigState);
            setConfigChanged(false);
          }}
          disabled={!canManageFulfillmentConfig || mutation.isLoading}
        >
          Reset
        </Button>
        <Button
          variant="primary"
          tone="success"
          disabled={
            !canManageFulfillmentConfig || !configChanged || mutation.isLoading
          }
          onClick={async () => {
            await mutation.mutateAsync({
              accessToken: currentUserData.accessToken,
              config: encodeFulfillmentConfigStateByDomain(configState),
            });
            setConfigChanged(false);
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
}

export function FulfillmentsConfigPage({ currentUserData }: DefaultPageProps) {
  const webHookArea = hasPermissions(
    currentUserData,
    "manage_shopify_webhooks",
  ) && <ShopifyFulfillmentStatusBtn currentUserData={currentUserData} />;

  const { data: fulfillmentConfigByDomain, isLoading } =
    useFulfillmentConfigByDomain(currentUserData.accessToken);

  const spinner = isLoading && <Spinner />;

  const fulfillmentConfigEntries = fulfillmentConfigByDomain && (
    <FulfillmentConfigEntry
      configByDomain={fulfillmentConfigByDomain}
      currentUserData={currentUserData}
    />
  );

  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          <Heading>Fulfillments Configuration</Heading>
          {webHookArea}
          <PText>
            Manage the relevant configuration for automated fulfillment of
            orders of records in each area below.
          </PText>
          <PText>
            Product ID refers to the Shopify product ID of the product that,
            when purchased, should be fulfilled by this system. If you don't
            know how to find a product's product ID, consult the Shopify
            administrator.
          </PText>
          <PText>
            Expiration Window and Period controls how long temporary links to
            purchased certificate media will be valid from time of fulfillment.
            You can set different expiration periods for each record set.
          </PText>
          {spinner || fulfillmentConfigEntries}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}

export function FulfillmentsAdminPage(props: DefaultPageProps) {
  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          <Heading>Fulfillments Admin</Heading>
          {hasPermissions(props.currentUserData, "view_fulfillment_config") && (
            <NavCard heading="Fulfillment Config" url="./config">
              Manage fulfillment configuration, including assigning products to
              fulfill and setting expiration windows for each dataset.
            </NavCard>
          )}
          {hasPermissions(props.currentUserData, "manage_fulfillments") && (
            <NavCard heading="Fulfilled Orders" url="./orders">
              View orders that have been fulfilled by the system and manage
              their status.
            </NavCard>
          )}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
