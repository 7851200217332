import * as yup from "yup";
import { SearchFilterFields, SearchSortFields } from "../search/searchutils";
import { Labels } from "./core";

export const orderSchema = yup.object({
  id: yup.string().required(),
  orderId: yup.number().required(),
  orderNo: yup.string().required(),
  orderDt: yup.date().required(),
  contactEmail: yup.string().required(),
});

export type Order = yup.InferType<typeof orderSchema>;

export const orderLabels: Labels<Order> = {
  id: "UUID",
  orderId: "Order ID",
  orderNo: "Order Number",
  orderDt: "Order Creation Date",
  contactEmail: "Customer Email",
};

export const orderSorts: SearchSortFields<Order> = {
  orderNo: { label: orderLabels["orderNo"] },
  contactEmail: { label: orderLabels["contactEmail"] },
  orderDt: { label: orderLabels["orderDt"] },
};

export const orderFilters: SearchFilterFields = {
  orderNo: {
    label: orderLabels["orderNo"],
    type: "string",
    defaultOperator: "$",
  },
  contactEmail: { label: orderLabels["contactEmail"], type: "string" },
  orderDt: { label: orderLabels["orderDt"], type: "date" },
};
