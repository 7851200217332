import * as yup from "yup";
import { Labels, userSchema } from "./core";
import { orderSchema } from "./order";

export const fulfillmentSchema = yup.object({
  id: yup.string().required(),
  orderId: yup.string().required(),
  lineItemId: yup.number().required(),
  domain: yup.string().required(),
  hash: yup.string().required(),
  identification: yup.string().required(),
  expirationDt: yup.date().required(),
  lastFulfillmentDt: yup.date().optional().nullable(),
  lastFulfilledBy: userSchema.optional(),
});

export type Fulfillment = yup.InferType<typeof fulfillmentSchema>;

export const fulfillmentLabels: Labels<Fulfillment> = {
  id: "UUID",
  orderId: "Order UUID",
  lineItemId: "Line Item ID",
  hash: "Media Hash",
  domain: "Record Type",
  identification: "Record Identification",
  expirationDt: "Expiration Date",
  lastFulfillmentDt: "Last Fulfillment Date",
  lastFulfilledBy: "Last Fulilled By",
};

export const orderWFulfillmentsSchema = orderSchema.shape({
  fulfillments: yup.array(fulfillmentSchema).required(),
});

export type OrderWFulfillments = yup.InferType<typeof orderWFulfillmentsSchema>;

export const ExpWindowPat = /([0-9]+)([Mhdw])/;

export const fulfillmentConfigSchema = yup.object({
  productId: yup.number().optional().nullable(),
  expWindow: yup.string().matches(ExpWindowPat).required(),
});

export type FulfillmentConfig = yup.InferType<typeof fulfillmentConfigSchema>;

export const fulfillmentConfigByDomainSchema = yup.object({
  birthRecords: fulfillmentConfigSchema,
  deathRecords: fulfillmentConfigSchema,
});

export type FulfillmentConfigByDomain = yup.InferType<
  typeof fulfillmentConfigByDomainSchema
>;

const EXPPERIOD = ["M", "h", "d", "w"] as const;

export type ExpPeriod = (typeof EXPPERIOD)[number];

export const fulfillmentRefreshInputSchema = yup.object({
  emailOverride: yup.string().optional().nullable(),
  expWindowOverride: yup
    .object({
      expValue: yup.number().required(),
      expPeriod: yup
        .string()
        .required()
        .oneOf([...EXPPERIOD]),
    })
    .optional()
    .nullable(),
});

export type FulfillmentRefreshInput = yup.InferType<
  typeof fulfillmentRefreshInputSchema
>;

export type FulfillmentConfigState = {
  productId: number | null | undefined;
  expValue: number;
  expPeriod: ExpPeriod;
};

export type FulfillmentConfigStateByDomain = {
  birthRecords: FulfillmentConfigState;
  deathRecords: FulfillmentConfigState;
};

export function decodeFulfillmentConfigByDomain(
  conf: FulfillmentConfigByDomain,
): FulfillmentConfigStateByDomain {
  const decodeFulfillmentConfig = (
    cfg: FulfillmentConfig,
  ): FulfillmentConfigState => {
    const parts = cfg.expWindow.match(ExpWindowPat);
    if (!parts) {
      // This shouldn't ever actually happen cause the api does its own validation,
      // but this satisfies the type checker and if the client ever generates its
      // own FulfillmentConfigs this is a good check for devs.
      throw Error(`${cfg} has invalid expWindow value.`);
    }
    let value = Number(parts[1]);
    let period = parts[2] as ExpPeriod;
    return {
      productId: cfg.productId,
      expPeriod: period,
      expValue: value,
    };
  };

  return Object.entries(conf).reduce(
    (prev, [k, cfg]) => ({ ...prev, [k]: decodeFulfillmentConfig(cfg) }),
    {} as FulfillmentConfigStateByDomain,
  );
}

export function encodeFulfillmentConfigStateByDomain(
  conf: FulfillmentConfigStateByDomain,
): FulfillmentConfigByDomain {
  const encodeFulfillmentConfigState = (
    cfg: FulfillmentConfigState,
  ): FulfillmentConfig => ({
    productId: cfg.productId,
    expWindow: `${cfg.expValue}${cfg.expPeriod}`,
  });

  return Object.entries(conf).reduce(
    (prev, [k, cfg]) => ({ ...prev, [k]: encodeFulfillmentConfigState(cfg) }),
    {} as FulfillmentConfigByDomain,
  );
}
