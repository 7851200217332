export function get_env_var(var_name: string, fallback: string): string;
export function get_env_var(
  var_name: string,
  fallback?: string,
): string | undefined;
export function get_env_var(
  var_name: string,
  fallback?: string,
): string | undefined {
  const val = process.env[var_name] || fallback;
  //   if (!val) {
  //     throw new Error(`Missing environment variable: ${var_name}`);
  //   }
  return val;
}

/**
 FIXME: Fallbacks are (temporarily??) necessary for all required variables
 because of storybook. If we have get_env_var throw a helpful error when variables 
 are missing, then we can't use ANY react features in stories. This is because 
 importing react or react features (e.g. useState) will silently attempt to load 
 this file in such a way that even when these variables are provided to the
 storybook environment via any of the recommended methods, react can't see
 them or doesn't receive them in time or something. They also cannot be made 
 optional because then the client won't build due to type-checking failures.
 */
export const API_URL = get_env_var("REACT_APP_API_URL", "foobar");
export const SHOPIFY_API_KEY = get_env_var(
  "REACT_APP_SHOPIFY_API_KEY",
  "foobar",
);
export const SHOPIFY_API_OAUTH_SCOPE_PATH = get_env_var(
  "REACT_APP_SHOPIFY_API_OAUTH_SCOPE_PATH",
  "foobar",
);
export const SHOPIFY_STORE_LOGIN_URL = get_env_var(
  "REACT_APP_SHOPIFY_STORE_LOGIN_URL",
  "foobar",
);
export const SHOPIFY_STORE_URL = get_env_var(
  "REACT_APP_SHOPIFY_STORE_URL",
  "foobar",
);
export const SHOPIFY_ADMIN_URL = get_env_var(
  "REACT_APP_SHOPIFY_ADMIN_URL",
  "foobar",
);

export const RQUERY_STALE_TIME =
  1000 * 60 * parseInt(get_env_var("REACT_APP_RQUERY_STALE_TIME_MINUTES", "5"));

// Used to control some visual indicators in the client:
export const ENV = get_env_var("REACT_APP_ENV", "stage");

// FEATURES
export const FEATURE_BIRTH_RECORDS =
  get_env_var("REACT_APP_FEATURE_BIRTH_RECORDS") === "True";
export const FEATURE_DEATH_RECORDS =
  get_env_var("REACT_APP_FEATURE_DEATH_RECORDS") === "True";
export const FEATURE_GOLD_STAR =
  get_env_var("REACT_APP_FEATURE_GOLD_STAR") === "True";
export const FEATURE_VETS_GRAVE =
  get_env_var("REACT_APP_FEATURE_VETS_GRAVE") === "True";
export const FEATURE_CENSUS =
  get_env_var("REACT_APP_FEATURE_CENSUS") === "True";
export const FEATURE_COLLECTIONS =
  get_env_var("REACT_APP_FEATURE_COLLECTIONS") === "True";
export const FEATURE_WWI_SERVICE =
  get_env_var("REACT_APP_FEATURE_WWI_SERVICE") === "True";
export const FEATURE_WWI_BONUSES =
  get_env_var("REACT_APP_FEATURE_WWI_BONUSES") === "True";
export const FEATURE_WWI_SOL_PHOTOS =
  get_env_var("REACT_APP_FEATURE_WWI_SOL_PHOTOS") === "True";
export const FEATURE_FIND_AIDS =
  get_env_var("REACT_APP_FEATURE_FIND_AIDS") === "True";
export const FEATURE_BULK_UPDATE =
  get_env_var("REACT_APP_FEATURE_BULK_UPDATE") === "True";
export const FEATURE_COMMENTS =
  get_env_var("REACT_APP_FEATURE_COMMENTS") === "True";
export const FEATURE_AUTO_FULFILLMENT =
  get_env_var("REACT_APP_FEATURE_AUTO_FULFILLMENT") === "True";
