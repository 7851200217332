import * as yup from "yup";
import { SearchFilterFields, SearchSortFields } from "../search/searchutils";
import { Dataset, DATASETS, DOMAIN, Labels, userSchema } from "./core";

export const CMT_ACCESS_LEVELS = ["public", "unpublished"] as const;
export type CmtAccessLevel = (typeof CMT_ACCESS_LEVELS)[number];

export const CMT_CERTIFICATION = ["basic", "staff", "mdh"] as const;
export type CmtCertification = (typeof CMT_CERTIFICATION)[number];

export const commentInputSchema = yup.object({
  id: yup.string().nullable(),
  content: yup.string().required(),
  userName: yup.string().required(),
  email: yup.string().required(),
  dataset: yup
    .string()
    .required()
    .oneOf([...DATASETS]),
  assignedTo: yup.string().required(),
  commentDt: yup.date().optional(),
  accessLevel: yup
    .string()
    .required()
    .oneOf([...CMT_ACCESS_LEVELS]),
  replyTo: yup.string().optional().nullable(),
  certification: yup
    .string()
    .required()
    .oneOf([...CMT_CERTIFICATION]),
});

export type CommentInput = yup.InferType<typeof commentInputSchema>;

// Comments have no schema because of the infinitely nested replies, which yup
// doesn't handle well.
export type Comment = {
  id: string;
  content: string;
  userName: string;
  email: string;
  dataset: Dataset;
  assignedTo: string;
  commentDt: Date;
  accessLevel: CmtAccessLevel;
  replyTo: string | null;
  replies: Comment[];
  certification: CmtCertification;
  isStaffComment: boolean;
};

export const commentLabels: Labels<Comment> = {
  id: "UUID",
  content: "Content",
  userName: "User Name",
  email: "Email Address",
  dataset: "Assigned Record Type",
  assignedTo: "Assigned to Record UUID",
  commentDt: "Comment Date",
  accessLevel: "Status",
  replyTo: "Reply to Comment UUID",
  replies: "Replies",
  certification: "Source",
  isStaffComment: "Is Staff Comment",
};

export const commentSorts: SearchSortFields<Comment> = {
  commentDt: { label: commentLabels["commentDt"] },
  userName: { label: commentLabels["userName"] },
  email: { label: commentLabels["email"] },
  accessLevel: { label: commentLabels["accessLevel"] },
  certification: { label: commentLabels["certification"] },
};

export const commentFilters: SearchFilterFields = {
  email: { label: commentLabels["email"], type: "string" },
  userName: { label: commentLabels["userName"], type: "string" },
  commentDt: { label: commentLabels["commentDt"], type: "date" },
  certification: {
    label: commentLabels["certification"],
    type: "string",
    validTerms: [...CMT_CERTIFICATION],
  },
  dataset: {
    label: commentLabels["dataset"],
    type: "string",
    validTerms: [...DATASETS],
  },
};

export const commentSubInputSchema = yup.object({
  id: yup.string().nullable(),
  email: yup.string().required(),
  recordId: yup.string().required(),
  domain: yup.string().required().oneOf(DOMAIN),
  subscribeDt: yup.date().optional(),
});

export type CommentSubInput = yup.InferType<typeof commentSubInputSchema>;

export const commentSubSchema = commentSubInputSchema.shape({
  id: yup.string().required(),
  subscribeDt: yup.date().required(),
});

export type CommentSub = yup.InferType<typeof commentSubSchema>;

export const commentSubLabels: Labels<CommentSub> = {
  id: "UUID",
  email: "Email",
  recordId: "Record UUID",
  domain: "Dataset",
  subscribeDt: "Subscription Date",
};

export const commentUrlPatsInputSchema = yup.object({
  "birth-records": yup.string().required(),
  "catalogue-items": yup.string().required(),
  census: yup.string().required(),
  "death-records": yup.string().required(),
  "gold-star": yup.string().required(),
  "vets-graves": yup.string().required(),
});

export type CommentUrlPatsInput = yup.InferType<
  typeof commentUrlPatsInputSchema
>;

export type CommentUrlPats = yup.InferType<typeof commentUrlPatsInputSchema>;

export const commentUrlPatsLabels: Labels<CommentUrlPats> = {
  "birth-records": "Birth Records",
  "catalogue-items": "Collections Items",
  census: "Census Records",
  "death-records": "Death Records",
  "gold-star": "Gold Star Roll Records",
  "vets-graves": "Veterans' Grave Records",
};

export const commentBlockedEmailInputSchema = yup.object({
  id: yup.string().nullable(),
  email: yup.string().required(),
  blockDt: yup.date().optional(),
  blockedBy: yup.string().required(),
  reason: yup.string().optional(),
});

export type CommentBlockedEmailInput = yup.InferType<
  typeof commentBlockedEmailInputSchema
>;

export const commentBlockedEmailSchema = commentBlockedEmailInputSchema.shape({
  id: yup.string().required(),
  blockDt: yup.date().required(),
  blockedBy: userSchema,
});

export type CommentBlockedEmail = yup.InferType<
  typeof commentBlockedEmailSchema
>;

export const commentBlockedEmailLabels: Labels<CommentBlockedEmail> = {
  id: "UUID",
  email: "Email",
  blockDt: "Block Date",
  blockedBy: "Blocked By",
  reason: "Reason",
};

export const commentBlockedEmailSorts: SearchSortFields<CommentBlockedEmail> = {
  email: { label: commentBlockedEmailLabels["email"] },
  blockDt: { label: commentBlockedEmailLabels["blockDt"] },
};

export const commentBlockedEmailFilters: SearchFilterFields = {
  email: { label: commentBlockedEmailLabels["email"], type: "string" },
  blockDt: { label: commentBlockedEmailLabels["blockDt"], type: "date" },
  reason: {
    label: commentBlockedEmailLabels["reason"],
    type: "string",
    defaultOperator: "*",
  },
};
