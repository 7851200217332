import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import { axiosInstance, baseAxiosConfig } from "../api/shared";
import {
  PagedResults,
  parseSearchParamsToFilters,
  Query,
  Sort,
} from "../schemas/core";
import {
  FulfillmentConfigByDomain,
  fulfillmentConfigByDomainSchema,
  FulfillmentRefreshInput,
  OrderWFulfillments,
  orderWFulfillmentsSchema,
} from "../schemas/fulfillment";
import { Order } from "../schemas/order";
import { FilterParamLike } from "../search/searchutils";

const shopifyFulfillmentRoot = "/shopify/fulfillment";
const shopifyFulfillmentStatus = "shopify-fulfillment-status";
const fulfillmentsEndpoint = "fulfillments";
const ordersEndpoint = `${fulfillmentsEndpoint}/orders`;
const fulfillmentsConfigEndpoint = "config";

export function useFulfillmentActiveStatus(accessToken: string) {
  const getActiveStatus = async (accessToken: string): Promise<boolean> => {
    const resp = await axiosInstance.get(
      `${shopifyFulfillmentRoot}/status`,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };
  return useQuery(
    [shopifyFulfillmentStatus],
    async () => await getActiveStatus(accessToken),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useToggleFulfillmentActivation() {
  const toggleActivation = async (
    accessToken: string,
    activate: boolean,
  ): Promise<null> => {
    const urlSuffix = activate ? "/activate" : "/deactivate";
    await axiosInstance.put(
      `${shopifyFulfillmentRoot}${urlSuffix}`,
      null,
      baseAxiosConfig(accessToken),
    );
    return null;
  };

  const queryClient = useQueryClient();

  return useMutation(
    ({ accessToken, activate }: { accessToken: string; activate: boolean }) => {
      return toggleActivation(accessToken, activate);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([shopifyFulfillmentStatus]);
      },
    },
  );
}

export function useFulfillmentConfigByDomain(accessToken: string) {
  const getFulfillmentConfigByDomain = async (
    accessToken: string,
  ): Promise<FulfillmentConfigByDomain> => {
    const resp = await axiosInstance.get(
      `/${fulfillmentsEndpoint}/${fulfillmentsConfigEndpoint}`,
      baseAxiosConfig(accessToken),
    );
    const result = await fulfillmentConfigByDomainSchema.validate(resp.data);
    return result;
  };

  return useQuery(
    [fulfillmentsEndpoint, fulfillmentsConfigEndpoint],
    async () => await getFulfillmentConfigByDomain(accessToken),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateFulfillmentConfigByDomain() {
  const updateFulfillmentConfigByDomain = async (
    accessToken: string,
    config: FulfillmentConfigByDomain,
  ) => {
    await axiosInstance.post(
      `/${fulfillmentsEndpoint}/${fulfillmentsConfigEndpoint}`,
      config,
      baseAxiosConfig(accessToken),
    );
  };

  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      config,
    }: {
      accessToken: string;
      config: FulfillmentConfigByDomain;
    }) => {
      return updateFulfillmentConfigByDomain(accessToken, config);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([
          fulfillmentsEndpoint,
          fulfillmentsConfigEndpoint,
        ]);
      },
    },
  );
}

export function useOrder(accessToken: string, uuid?: string | null) {
  const getOrder = async (
    accessToken: string,
    uuid: string,
  ): Promise<OrderWFulfillments> => {
    const resp = await axiosInstance.get(
      `/${ordersEndpoint}/${uuid}`,
      baseAxiosConfig(accessToken),
    );
    const order = await orderWFulfillmentsSchema.validate(resp.data);
    return order;
  };

  return useQuery(
    [ordersEndpoint, uuid],
    async () =>
      accessToken && uuid ? await getOrder(accessToken, uuid) : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useOrders(
  accessToken: string,
  pageNum: number,
  pageSize: number,
  sorts?: Sort[],
  filters?: FilterParamLike[],
) {
  const query: Query = {
    sorts: sorts || [],
    filters: parseSearchParamsToFilters(filters) || [],
  };
  const queryOrders = async (): Promise<PagedResults<Order>> => {
    const resp = await axiosInstance.post(`/${ordersEndpoint}`, query, {
      ...baseAxiosConfig(accessToken),
      params: { pageNum, pageSize },
    });
    return resp.data;
  };
  return useQuery(
    [ordersEndpoint, "query", pageNum, pageSize, sorts, filters],
    async () => await queryOrders(),
    { staleTime: RQUERY_STALE_TIME, keepPreviousData: true },
  );
}

export interface RefreshFulfillmentParams {
  accessToken: string;
  uuid: string;
  refreshInput: FulfillmentRefreshInput;
}

export function useRefreshOrder() {
  const refreshOrder = async (
    accessToken: string,
    uuid: string,
    refreshInput: FulfillmentRefreshInput,
  ) => {
    let winOverride = refreshInput.expWindowOverride;
    let body = {
      emailOverride: refreshInput.emailOverride,
      expWindowOverride: winOverride
        ? `${winOverride.expValue}${winOverride.expPeriod}`
        : null,
    };
    console.log(body);
    await axiosInstance.put(
      `${ordersEndpoint}/${uuid}/refresh`,
      body,
      baseAxiosConfig(accessToken),
    );
  };

  const queryClient = useQueryClient();

  return useMutation(
    ({ accessToken, uuid, refreshInput }: RefreshFulfillmentParams) => {
      return refreshOrder(accessToken, uuid, refreshInput);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([ordersEndpoint]);
      },
    },
  );
}
