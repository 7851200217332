import {
  Button,
  Checkbox,
  Divider,
  ResourceItem,
  ResourceList,
  Tooltip,
} from "@shopify/polaris";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../shared/Card";
import Stack from "../shared/Stack";
import { Dot, Heading, PText, Subheading } from "../shared/TextComponents";
import BreadcrumbPage from "./BreadcrumbPage";
import { DetailFields } from "./Detail";
import { FulfillmentRefreshForm } from "./Fulfillments";
import { useOrder, useOrders, useRefreshOrder } from "./hooks/fulfillmentHooks";
import { QueryHandler } from "./QueryHandler";
import { CurrentUserData } from "./schemas/core";
import { OrderWFulfillments } from "./schemas/fulfillment";
import { Order, orderFilters, orderLabels, orderSorts } from "./schemas/order";
import {
  DefaultPageProps,
  renderDatelikeAsString,
  renderValueAsString,
} from "./utils/shared";
import { SHOPIFY_ADMIN_URL } from "../env";

export default function OrdersPage(props: DefaultPageProps) {
  const navigate = useNavigate();

  const renderItem = (item: Order) => (
    <Stack>
      <Subheading>{`Order ${item.orderNo}`}</Subheading>
      <PText>
        {item.contactEmail} {Dot} {renderDatelikeAsString(item.orderDt)}
      </PText>
    </Stack>
  );

  return (
    <BreadcrumbPage>
      <Card>
        <QueryHandler
          currentUserData={props.currentUserData}
          queryHook={useOrders}
          sortFieldsSpec={orderSorts}
          filterFieldsSpec={orderFilters}
          labels={orderLabels}
          renderResultItem={renderItem}
          renderResultItemOnClick={(id) => navigate(`./${id}`)}
          renderItemAccessibilityLabel={(item) =>
            `Order details for order number ${item.orderNo}.`
          }
          heading="Fulfilled Orders"
          defaultSorts={{
            orderDt: { field: "orderDt", direction: "desc" },
          }}
        />
      </Card>
    </BreadcrumbPage>
  );
}

interface OrderDetailProps {
  currentUserData: CurrentUserData;
  order?: OrderWFulfillments | null;
}

function OrderDetail({ currentUserData, order }: OrderDetailProps) {
  const [extendExp, setExtendExp] = useState(false);

  const now = new Date();

  const mutation = useRefreshOrder();

  const detail = order && (
    <Stack>
      <Heading>{`Order ${order.orderNo}`}</Heading>
      <Stack direction="row">
        <Button
          variant="plain"
          onClick={() =>
            window.open(`${SHOPIFY_ADMIN_URL}/orders/${order.orderId}`)
          }
        >
          View in Shopify
        </Button>
      </Stack>
      <DetailFields
        record={order}
        spec={{
          orderDt: { dateType: "datetime" },
          contactEmail: null,
        }}
        labels={orderLabels}
      />
      <Subheading>Line Items</Subheading>
      <PText>{order.fulfillments.length} fulfilled line item(s).</PText>
      <ResourceList
        resourceName={{ singular: "line item", plural: "line items" }}
        items={order.fulfillments}
        renderItem={(fulfillment) => (
          <ResourceItem
            id={fulfillment.id}
            accessibilityLabel={`Line item detail for ${fulfillment.identification}`}
            onClick={() => {}}
          >
            <Stack direction="row">
              <PText weight="bold">{fulfillment.identification}</PText>
              <PText>Temp link expires:</PText>
              <PText
                tone={fulfillment.expirationDt < now ? "critical" : "success"}
              >
                {renderDatelikeAsString(fulfillment.expirationDt)}
              </PText>
            </Stack>
            <Stack direction="row">
              {fulfillment.lastFulfilledBy && (
                <PText tone="subdued">
                  Last fulfilled by {fulfillment.lastFulfilledBy.name} on{" "}
                  {renderValueAsString(fulfillment.lastFulfillmentDt)}
                </PText>
              )}
              {!fulfillment.lastFulfilledBy && (
                <PText>
                  Order unfulfilled! Click Refresh below to fulfill it.
                </PText>
              )}
            </Stack>
            <Stack direction="row" justify="flex-end">
              <Tooltip content="Refresh just this line item">
                <Button disabled>Refresh</Button>
              </Tooltip>
            </Stack>
          </ResourceItem>
        )}
      />
      <PText>
        <strong>NOTE:</strong> The above record details are not updated as
        records are changed. They were copied at the time of the order and are
        what the customer would have seen at the time!
      </PText>
      <Divider />
      <Subheading>Refresh Fulfillment</Subheading>
      <PText>
        Sends the customer another email containing temp links to the records
        above.
      </PText>
      <PText>
        Optionally, you may send the email to a different address than the one
        they used when placing the order, and you can extend the expiration
        dates of the temp links (expirations are extended from the time you
        refresh the fulfillments.)
      </PText>
      <Stack direction="row">
        <Checkbox
          label="Extend Expiration(s)"
          checked={extendExp}
          onChange={(value) => setExtendExp(value)}
        />
      </Stack>
      <FulfillmentRefreshForm
        currentUserData={currentUserData}
        mutation={mutation}
        uuid={order.id}
        refreshInput={{ emailOverride: order.contactEmail }}
        direction="row"
        showExpOverride={extendExp}
      />
    </Stack>
  );

  return <Stack>{detail}</Stack>;
}

export function OrderDetailPage(props: DefaultPageProps) {
  const { uuid } = useParams();

  const { data: order } = useOrder(props.currentUserData.accessToken, uuid);

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Stack>
        <Card>
          <OrderDetail currentUserData={props.currentUserData} order={order} />
        </Card>
      </Stack>
    </BreadcrumbPage>
  );
}
