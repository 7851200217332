import { Button } from "@shopify/polaris";
import { Domain } from "../schemas/core";
import { getVitalRecordsDisplayName } from "../services/shared";
import { renderValueAsString } from "./shared";
import { SHOPIFY_STORE_URL } from "../../env";

const DomainFulfillmentTypeMap: Record<Domain, string | null> = {
  "birth-records": "fulfillment_birth_certificates",
  census: null,
  "catalogue-items": null,
  "death-records": "fulfillment_death_certificates",
  "gold-star": null,
  "vets-graves": null,
  "wwi-bonuses": null,
  "wwi-service": null,
  "wwi-soldiers-photos": null,
};

type VitalRecordLike = {
  id: string;
  certificateId?: string | null | undefined;
  firstName?: string | null | undefined;
  middleName?: string | null | undefined;
  familyName?: string | null | undefined;
};

interface ShopifyAddToCartBtnProps<T extends VitalRecordLike> {
  record: T;
  domain: Domain;
  productId: number;
  displayDate?: Date;
  displayLocation?: string;
  thumbnailLink?: string;
}

export default function ShopifyAddToCartBtn<T extends VitalRecordLike>({
  domain,
  record,
  productId,
  displayDate,
  displayLocation,
  thumbnailLink,
}: ShopifyAddToCartBtnProps<T>) {
  const fulfillmentType = DomainFulfillmentTypeMap[domain];

  return (
    fulfillmentType && (
      <form
        action={`${SHOPIFY_STORE_URL}/cart/add`}
        method="post"
        encType="multipart/form-data"
        target="_blank"
      >
        <input
          type="hidden"
          id="uuid"
          name="properties[_uuid]"
          value={record.id}
        />
        <input
          type="hidden"
          id="collection"
          name="properties[collection]"
          value={domain}
        />
        <input type="hidden" id="id" name="id" value={productId} />
        <input
          type="hidden"
          id="mnhs_fulfillment"
          name="properties[_mnhs_fulfillment]"
          value={fulfillmentType}
        />
        {/* I don't actually know what this systemid refers to or if/how shopify 
      uses it, it's 1 for vital records and 3 for "library". */}
        <input type="hidden" id="systemid" name="systemid" value="1" />
        {/* imagelink is used for some types of records, namely catalogue-items, 
        that have publicly available thumbnails.  */}
        <input
          type="hidden"
          id="imagelink"
          name="properties[imagelink]"
          value={thumbnailLink || ""}
        />
        <input
          type="hidden"
          id="title"
          name="properties[title]"
          value={getVitalRecordsDisplayName(record)}
        />
        <input
          type="hidden"
          id="eventdate"
          name="properties[eventdate]"
          value={
            displayDate
              ? renderValueAsString(displayDate, "date")
              : "Unknown Date"
          }
        />
        <input
          type="hidden"
          id="identifier"
          name="properties[identifier]"
          value={record.certificateId || "Unknown Certificate ID"}
        />
        <input
          type="hidden"
          id="spatial"
          name="properties[spatial]"
          value={displayLocation || "Unknown Location"}
        />
        <Button submit>Add To Order</Button>
      </form>
    )
  );
}
