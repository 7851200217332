import { Layout, Page } from "@shopify/polaris";
import { PText } from "../../shared/TextComponents";
import { getCount, searchRecords } from "../api/genericEndpoints";
import { hasPermissions } from "../auth/authutils";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { SearchQueryProps } from "../search/searchutils";
import { getVitalRecordsDisplayName } from "./shared";
import {
  wwiBonusFilters,
  wwiBonusLabels,
  WWIBonusRecord,
  wwiBonusSorts,
} from "../schemas/wwiBonus";
import WWIBonusForm from "./WWIBonusForm";
import WWIBonusDetail from "./WWIBonusDetail";

export function renderWWIBonusHit(record: WWIBonusRecord) {
  const displayName = getVitalRecordsDisplayName(record);

  return (
    <>
      <PText weight="bold">{displayName}</PText>
      <PText>
        Certificate No: {record.certificateId} ({record.outcome})
      </PText>
      <PText>{record.residence}</PText>
    </>
  );
}

export default function WWIBonusSearchPage(
  props: BaseSearchPageComponentProps<WWIBonusRecord>,
) {
  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={wwiBonusLabels}
          renderSearchHit={{
            render: renderWWIBonusHit,
            hitRenderMedia: "person",
            accessibilityLabel: (record) =>
              `View details for ${getVitalRecordsDisplayName(record)}`,
          }}
          renderSearchNavDetail={(record, onEditBtnClick) =>
            WWIBonusDetail({
              currentUserData: props.currentUserData,
              record,
              onEditBtnClick,
            })
          }
          renderSearchNavForm={(record, onSubmitSuccess, onDeleteSuccess) =>
            WWIBonusForm({
              currentUserData: props.currentUserData,
              record,
              onSubmitSuccess,
              onDeleteSuccess,
            })
          }
          sortFields={wwiBonusSorts}
          filterFields={wwiBonusFilters}
          searchQuery={async (props: SearchQueryProps) =>
            searchRecords<WWIBonusRecord>(props, "wwi-bonuses")
          }
          countQuery={async (accessToken: string) =>
            getCount(accessToken, "wwi-bonuses")
          }
          recordName="WWI Bonus Record"
          contextualPrefs={props.contextualPrefs}
          allowEditMode={hasPermissions(
            props.currentUserData,
            "add_edit_remove_wwi_bonus_records",
          )}
        />
      </Layout>
    </Page>
  );
}
