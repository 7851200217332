import { Banner, Button, Divider, Tabs } from "@shopify/polaris";
import { CheckCircleIcon } from "@shopify/polaris-icons";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText, Subheading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import {
  useCmtSubsByRecord,
  useCommentsByRecord,
  useSubscribe,
  useUnsubscribe,
  useUpdateComment,
} from "../hooks/commentHooks";
import { Comment, CommentInput } from "../schemas/comment";
import { DATASET_DOMAIN_MAP } from "../schemas/core";
import { CurrentUserData, Dataset } from "../schemas/core";
import { IndentedDiv } from "../utils/IndentedDiv";
import CommentDetail from "./CommentDetail";
import { CommentFormModal } from "./CommentForm";
import CommentSubs from "./CommentSubs";
import { CommentBlockEmailModal } from "./CommentBlockedEmails";

interface CommentThreadProps {
  comments: Comment[];
  onEditBtnClick?: (cmt: Comment) => void;
  onSubmitEdit?: (input: CommentInput) => void;
  editIsLoading?: boolean;
  onBlockClick?: (email: string) => void;
  blockIsLoading?: boolean;
  onReply?: (replyToCmt: Comment) => void;
  indent?: number;
}

export function CommentThread({
  comments,
  onEditBtnClick,
  onSubmitEdit,
  editIsLoading,
  onBlockClick,
  blockIsLoading,
  onReply,
  indent = 0,
}: CommentThreadProps) {
  return (
    <IndentedDiv level={indent}>
      <Stack>
        {comments.map((cmt) => (
          <Stack key={cmt.id}>
            <CommentDetail
              key={cmt.id}
              comment={cmt}
              hideRecordDomainLabel
              onEditBtnClick={
                onEditBtnClick ? () => onEditBtnClick(cmt) : undefined
              }
              onSubmitEdit={onSubmitEdit}
              editIsLoading={editIsLoading}
              onBlockClick={
                onBlockClick ? () => onBlockClick(cmt.email) : undefined
              }
              blockIsLoading={blockIsLoading}
              additionalActions={
                onReply ? (
                  <Button
                    onClick={() => {
                      onReply(cmt);
                    }}
                  >
                    Reply
                  </Button>
                ) : null
              }
            />
            <IndentedDiv level={indent + 2}>
              <CommentThread
                comments={cmt.replies}
                onEditBtnClick={onEditBtnClick}
                onSubmitEdit={onSubmitEdit}
                editIsLoading={editIsLoading}
                onReply={onReply}
              />
            </IndentedDiv>
          </Stack>
        ))}
      </Stack>
    </IndentedDiv>
  );
}

interface CommentSectionProps {
  recordId: string;
  currentUserData: CurrentUserData;
  dataset: Dataset;
  defaults?: { userName: string; email: string };
}

export default function CommentSection({
  recordId,
  currentUserData,
  dataset,
  defaults,
}: CommentSectionProps) {
  const search = useLocation().search;
  const subToken = new URLSearchParams(search).get("subToken");
  const unsubToken = new URLSearchParams(search).get("unsubToken");
  const unsubAll = new URLSearchParams(search).get("unsubAll");

  const [modalActive, setModalActive] = useState(false);
  const [activeCmt, setActiveCmt] = useState<Comment | undefined>();
  const [replyToCmt, setReplyToCmt] = useState<Comment | undefined>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showBanner, setShowBanner] = useState(true);
  const [blockPromptActive, setBlockPromptActive] = useState(false);
  const [emailToBlock, setEmailToBlock] = useState<string | undefined>();

  const mutation = useUpdateComment();

  const recordDomain = DATASET_DOMAIN_MAP[dataset];

  const closeCommentFormModal = () => {
    setActiveCmt(undefined);
    setReplyToCmt(undefined);
  };

  const { data: comments } = useCommentsByRecord(
    currentUserData.accessToken,
    recordId,
  );
  const { data: subs } = useCmtSubsByRecord(
    currentUserData.accessToken,
    // Only pass the record id (and thus trigger this hook to run the query) if
    // the user has permissions to view comment subscriptions.
    hasPermissions(currentUserData, "view_cmt_subscriptions")
      ? recordId
      : undefined,
  );
  const { data: subResp } = useSubscribe(subToken);
  const { data: unsubResp } = useUnsubscribe(unsubToken);

  const SubBanner = ({ msg }: { msg: string }) => (
    <Banner
      tone="success"
      onDismiss={() => setShowBanner(false)}
      icon={CheckCircleIcon}
    >
      <PText>{msg}</PText>
    </Banner>
  );

  const subBanner = subResp && showBanner && (
    <SubBanner msg="You have successfully subscribed to this record." />
  );

  const unsubMsg = unsubAll === "true" ? "all records" : "this record";

  const unsubBanner = unsubResp && showBanner && (
    <SubBanner msg={`You have successfully unsubscribed from ${unsubMsg}.`} />
  );

  const form = (
    <CommentFormModal
      promptTitle={activeCmt ? "Edit Comment" : "Add Comment"}
      currentUserData={currentUserData}
      modalActive={modalActive}
      setModalActive={setModalActive}
      onClose={closeCommentFormModal}
      keepFormVisible
      record={activeCmt}
      replyToCmt={replyToCmt}
      defaults={{
        userName: defaults?.userName || "",
        email: defaults?.email || "",
        dataset: dataset,
        assignedTo: recordId,
        certification: "staff",
        accessLevel: "public",
        content: "",
        replyTo: replyToCmt?.id,
      }}
    />
  );

  const cmtThread =
    comments && comments.length > 0 ? (
      <CommentThread
        comments={comments}
        editIsLoading={mutation.isLoading}
        onEditBtnClick={(cmt) => {
          setActiveCmt(cmt);
          setModalActive(true);
        }}
        onBlockClick={
          hasPermissions(currentUserData, "add_cmt_blocked_emails")
            ? (email: string) => {
                setEmailToBlock(email);
                setBlockPromptActive(true);
              }
            : undefined
        }
        onSubmitEdit={
          hasPermissions(currentUserData, "edit_comments")
            ? (input) =>
                mutation.mutate({
                  accessToken: currentUserData.accessToken,
                  inputData: input,
                })
            : undefined
        }
        onReply={
          hasPermissions(currentUserData, "add_comments")
            ? (cmt) => {
                setReplyToCmt(cmt);
                setModalActive(true);
              }
            : undefined
        }
      />
    ) : (
      "No comments found for this record."
    );

  const cmtSubs = subs && (
    <CommentSubs
      currentUserData={currentUserData}
      recordId={recordId}
      recordDomain={recordDomain}
      subs={subs}
    />
  );

  const blockEmailModal = (
    <CommentBlockEmailModal
      currentUserData={currentUserData}
      email={emailToBlock}
      setEmail={setEmailToBlock}
      blockPromptActive={blockPromptActive}
      setBlockPromptActive={setBlockPromptActive}
    />
  );

  const tabs = cmtThread && cmtSubs && (
    <Tabs
      tabs={[
        {
          id: "comments",
          content: "Comments",
          panelID: "comments",
        },
        {
          id: "subs",
          content: "Comment Subscriptions",
          panelID: "subs",
        },
      ]}
      selected={selectedTab}
      onSelect={(tabIdx) => setSelectedTab(tabIdx)}
    >
      {[<>{cmtThread}</>, <>{cmtSubs}</>][selectedTab]}
    </Tabs>
  );

  return (
    <Card>
      <Stack>
        {blockEmailModal}
        <Heading>Comments</Heading>
        {subBanner}
        {unsubBanner}
        {tabs || cmtThread}
        <Divider />
        {hasPermissions(currentUserData, "add_comments") && (
          <>
            <Subheading>Add Comment</Subheading>
            {form}
          </>
        )}
      </Stack>
    </Card>
  );
}
